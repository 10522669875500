import React, { useState, useEffect } from "react";
import { btnColor } from "../Accounts-styles";
import { Navigate, useNavigate, useParams } from "react-router";
import LoginIcon from "@mui/icons-material/Login";
import { Box, Container, Button, TextField, Stack, Link, CircularProgress, Alert } from "@mui/material";
import { useAuth } from "../context/AuthContext";
import authService from "../services/auth-service";
import accountService from "../services/accounts-service";
import Header from "../components/Header";
import createManifest from "../services/pwa-manifest-helper";
import { useTranslation } from "react-i18next";

export default function Login() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { account } = useParams();
  const { setToken, setUser } = useAuth();
  const { accountData, setAccountData } = useAuth();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState();
  const [btnSpinner, setBtnSpinner] = useState(false);

  const [payload, setPayload] = useState({
    username: "",
    password: "",
    accountId: account,
  });

  const login = async evt => {
    if (evt) {
      evt.preventDefault();
    }

    setBtnSpinner(true);
    setErrorMessage(null);
    try {
      authService.saveUsername(payload.username);
    } catch (error) {
      console.log("failed to save username to local storage");
    }
    try {
      authService
        .login(payload)
        .then(res => {
          console.log("login success", res);
          setToken(res.token);
          console.log("login success - token");
          setUser(authService.getUser());
          console.log("login success - set user", account);
          navigate(`/${account}`);
        })
        .catch(error => {
          console.log("Login failed", error);
          setErrorMessage(t("login.errMess1"));
        })
        .finally(() => {
          setBtnSpinner(false);
        });
    } catch (error) {
      console.log("Login failed unexpected", error);
      setErrorMessage(t("login.errMess2"));
    }
  };

  const getAccount = async () => {
    console.log("getAccount", account);
    return await accountService.getAccount(account);
  };

  useEffect(() => {
    (async () => {
      try {
        const username = authService.getUsername();
        setPayload({ ...payload, username: username });
      } catch (error) {
        console.log("Failed to get username from local storage");
      }

      try {
        if (accountData === null || accountData === undefined) {
          console.log("accountData not found.. getting");
          const a = await getAccount();
          console.log("account found", a);
          setAccountData(a);
          createManifest(a);
        }
      } catch (error) {
        console.log(error);
        setErrorMessage(t("login.noAccount", { account: account }));
      } finally {
        setLoading(false);
        setBtnSpinner(false);
      }
    })();
  }, []);

  return (
    <>
      <Header />

      <Box
        pt={2}
        sx={{
          background: accountData ? accountData.settings.bodyBackground : "#f5f5f5",
          height: "calc(100vh - 65px)",
        }}
      >
        <Container
          maxWidth="xs"
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Container sx={{ alignSelf: "center", marginTop: "40px" }}>
            <Stack mb={2} spacing={1}>
              {loading && <CircularProgress sx={{ color: btnColor }} />}
              {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            </Stack>
            {accountData && (
              <>
                <form onSubmit={login}>
                  <Stack spacing={2}>
                    <TextField
                      autoComplete="on"
                      onChange={e => setPayload({ ...payload, username: e.target.value })}
                      fullWidth={true}
                      id="outlined-basic"
                      label={t("login.emailPlaceholder")}
                      variant="filled"
                    />
                    <TextField
                      autoComplete="on"
                      onChange={e => setPayload({ ...payload, password: e.target.value })}
                      fullWidth={true}
                      id="outlined-basic"
                      label={t("login.pwdPlaceholder")}
                      variant="filled"
                      type="password"
                    />
                  </Stack>
                  <Button
                    fullWidth={true}
                    variant="contained"
                    sx={{ marginTop: "20px", background: btnColor }}
                    type="submit"
                  >
                    {t("login.login")}&nbsp;
                    {btnSpinner && <CircularProgress size={17} sx={{ color: "white" }} />}
                  </Button>
                </form>
                <Stack mt={2} direction="row" sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Link component="button" variant="body1" onClick={() => navigate(`/${account}/registeruser`)}>
                    {t("login.regUser")}
                  </Link>{" "}
                  <Link component="button" variant="body1" onClick={() => navigate(`/${account}/forgotpassword`)}>
                    {t("login.resetPwd")}
                  </Link>{" "}
                </Stack>
              </>
            )}
          </Container>
        </Container>
      </Box>
    </>
  );
}
