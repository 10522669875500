import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import standsService from "../../services/stands-service";
import { useNavigate, useParams } from "react-router";
import { btnColor } from "../../Accounts-styles";
import { useAuth } from "../../context/AuthContext";
import {
  Alert,
  Autocomplete,
  Button,
  Container,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box, typography } from "@mui/system";
import suppliersService from "../../services/suppliers-service";
import { useTranslation } from "react-i18next";

export default function StandCreate() {
  const { t } = useTranslation();
  const { account, id } = useParams();
  const { accountData } = useAuth();
  const navigate = useNavigate();
  const [standNr, setStandNr] = useState();
  const [visualNumber, setVisualNumber] = useState();
  const [suppliers, setSuppliers] = useState();
  const [supplier, setSupplier] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [supplierInputValue, setSupplierInputValue] = useState("");

  const [payload, setPayload] = useState({
    id: null,
    number: "",
    supplier: "",
    project: "",
    step: "",
    active: true,
    transportReference: null,
    barcodeFormat: "",
    orderReference: "",
    supplierId: "",
    visualNumber: "",
  });

  const getSuppliers = async () => {
    await suppliersService.getSuppliers().then((res) => {
      console.log(res);
      setSuppliers(res);
    });
  };

  const addStand = async (evt) => {
    console.log("supplier", supplier);
    if (evt) evt.preventDefault();

    setErrorMessage(null);
    if (supplier && standNr) {
      const s = suppliers.find((s) => {
        return s.name === supplier;
      });
      standsService
        .addStand(
          payload,
          ((payload.number = standNr),
          ((payload.supplier = supplier), (payload.supplierId = s.id)),
          (payload.visualNumber = visualNumber))
        )
        .then((stand) => {
          console.log("stand created", stand);
          navigate(`/${account}/${stand.id}`);
        })
        .catch((error) => {
          console.log("Failed to create stand", error);
          setErrorMessage(t("standCreate.errMess"));
        });
    }
  };

  useEffect(() => {
    (async () => {
      setVisualNumber(id === "0" ? "" : id);
      getSuppliers();
    })();
  }, []);

  return (
    <>
      <Box
        pt={2}
        sx={{
          background: accountData.settings.bodyBackground,
          height: "90vh",
          overflow: "auto",
        }}
      >
        <Container
          maxWidth="xs"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <form onSubmit={addStand}>
            <Stack mt={2} sx={{ width: { xs: "100%" } }}>
              <Container>
                <Stack mb={4} spacing={1}>
                  <Typography m={1}>{t("standCreate.standNumber")}</Typography>
                  <Alert severity="info">{t("standCreate.alert")}</Alert>
                  <TextField
                    fullWidth={true}
                    required
                    id="outlined-basic"
                    label={t("standCreate.labelStandNumber")}
                    variant="filled"
                    inputProps={{ minLength: 4, maxLength: 100 }}
                    onChange={(e) => setStandNr(e.target.value)}
                  />
                  <TextField
                    fullWidth={true}
                    id="outlined-basic"
                    label={t("standCreate.labelStandNumberShort")}
                    variant="filled"
                    defaultValue={id === "0" ? "" : id}
                    inputProps={{ minLength: 4, maxLength: 50 }}
                    onChange={(e) => setVisualNumber(e.target.value)}
                  />
                  <Typography m={1}>
                    {t("standCreate.chooseSupplier")}
                  </Typography>
                  {suppliers && (
                    <Autocomplete
                      // value={supplier && supplier}
                      id="supplier"
                      freeSolo
                      options={suppliers.map((option) => option.name)}
                      renderInput={(params) => (
                        <TextField
                          required
                          variant="filled"
                          {...params}
                          label={t("standCreate.supplier")}
                        />
                      )}
                      // onSelect={(e) => setSupplier(e.target.value)}
                      onChange={(e, newValue) => {
                        setSupplier(newValue);
                      }}
                    />
                  )}
                  <Button
                    sx={{
                      background: btnColor,
                    }}
                    variant="contained"
                    type="submit"
                  >
                    {t("standCreate.registerStand")}
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ borderColor: btnColor, color: btnColor }}
                    onClick={() => navigate(-1)}
                  >
                    {t("standCreate.back")}
                  </Button>
                  {errorMessage && (
                    <Alert severity="error">{errorMessage}</Alert>
                  )}
                </Stack>
              </Container>
            </Stack>
          </form>
        </Container>
      </Box>
    </>
  );
}
