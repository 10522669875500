import React, { useState, useEffect } from "react";
import supplierService from "../../services/suppliers-service";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Chip, Grid, Stack, TextField, Alert } from "@mui/material";
import { Box } from "@mui/system";
import DeleteButton from "../../components/DeleteButton";

import { useTranslation } from "react-i18next";

export default function SupplierItem(props) {
  const { t } = useTranslation();
  const { item, onAddItem, onDeleteItem } = props;
  const [supplier, setSupplier] = useState(item);
  const [errorMessage, setErrorMessage] = useState();
  const [expanded, setExpanded] = useState(false);
  const [newItem, setNewItem] = useState(false);
  const [canSave, setCanSave] = useState(false);

  const setFormValid = () => {
    setCanSave(supplier.name ? true : false);
  };

  const handleAccordionChange = e => {
    console.log(`handleAccordionChange '${expanded}'`);
    setExpanded(!expanded);
  };

  const handleChange = e => {
    setErrorMessage(null);
    const name = e.target.name;
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setSupplier({ ...supplier, [name]: value });
  };

  const saveSupplier = async () => {
    console.log("save supplier");
    supplierService
      .save(supplier)
      .then(p => {
        setExpanded(false);
        if (newItem) onAddItem(p);
      })
      .catch(e => {
        setErrorMessage(e.message ? e.message : e);
      });
  };

  const deleteSupplier = async () => {
    console.log("delete supplier");
    supplierService
      .deleteSupplier(supplier.id)
      .then(p => {
        if (onDeleteItem) onDeleteItem(supplier);
      })
      .catch(e => {
        setErrorMessage(e.message ? e.message : e);
      });
  };

  const cancelSave = () => {
    if (newItem) onAddItem();
    setExpanded(false);
  };

  useEffect(() => {
    if (supplier.id === null || supplier.id === undefined) {
      setExpanded(true);
      setNewItem(true);
    }
    setFormValid();
  }, []);
  return (
    <Accordion key={supplier.id} expanded={expanded} onChange={handleAccordionChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={supplier.id} id={supplier.id}>
        <Grid container>
          <Grid item xs={12}>
            {!newItem && supplier.name && (
              <Typography mr={4}>
                <strong>{supplier.name}</strong>
              </Typography>
            )}
            {newItem && (
              <Typography mr={4} color="primary.main">
                <strong>{t("supplierItem.newSupplier")}</strong>
              </Typography>
            )}
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <TextField
              fullWidth
              size="small"
              id="outlined-basic"
              label={t("supplierItem.nameLabel")}
              name="name"
              defaultValue={supplier.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack direction="row" spacing={2}>
              <Button onClick={saveSupplier} size="small" variant="contained">
                {t("supplierItem.save")}
              </Button>
              {newItem && (
                <Button onClick={cancelSave} size="small" variant="contained" sx={{ background: "#6c757d" }}>
                  {t("supplierItem.undo")}
                </Button>
              )}
              {!newItem && (
                <DeleteButton
                  itemId={item.id}
                  text={t("supplierItem.removeSupplier")}
                  onClick={deleteSupplier}
                  color="error"
                ></DeleteButton>
              )}
            </Stack>
          </Grid>
          {errorMessage && (
            <Grid item xs={12}>
              <Alert severity="error">{errorMessage}</Alert>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
