import axios from "axios";

const sendNotification = async payload => {
  const response = await axios.post(`/notifications`, payload);
  return response.data;
};

export default {
  sendNotification,
};
