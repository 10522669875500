import axios from "axios";

const createUser = async payload => {
  const response = await axios.post(`/users`, payload);
  return response.data;
};

const updateUser = async payload => {
  const response = await axios.put(`/users`, payload);
  return response.data;
};

const getUsers = async () => {
  const response = await axios.get(`/users`);
  return response.data;
};

const getUserById = async id => {
  const response = await axios.get(`/users/${id}`);
  return response.data;
};

const getUserRoles = async id => {
  const response = await axios.get(`/users/${id}/roles`);
  return response.data;
};

const addRoleToUser = async (id, role) => {
  const response = await axios.post(
    `/users/${id}/roles`,
    { role: role });
  return response.data;
};

const removeRole = async (userId, role) => {
  await axios.delete(`/users/${userId}/roles/${role}`);
};

const activateUser = async (id, payload) => {
  const response = await axios.post(`/users/${id}/activate`, payload);
  return response.data;
};

const changePassword = async (payload) => {
  const response = await axios.post(`/users/password`, payload);
  return response.data;
};

const changePasswordPreAuth = async (payload, token) => {
  const response = await axios.post(`/users/password`, payload);
  return response.data;
};

export default {
  createUser,
  updateUser,
  getUsers,
  getUserById,
  getUserRoles,
  addRoleToUser,
  removeRole,
  activateUser,
  changePassword,
  changePasswordPreAuth,
};
