export default function getQueryParams(obj) {
  if (obj) {
    let queryParams = null;
    Object.keys(obj).forEach((key) => {
      if (obj[key] && obj[key] !== '') {
        if (queryParams) {
          queryParams += `&${key}=${obj[key]}`;
        }
        else {
          queryParams = `?${key}=${obj[key]}`;
        }
      }
    });
    return queryParams;
  } else {
    return null;
  }
}
