import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../context/AuthContext";
import authService from "../../services/auth-service";
import usersService from "../../services/users-service";
import Main from "../Main";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import { Alert, CircularProgress, Container, Fab, Grid, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import UserItem from "./UserItem";
import { useTranslation } from "react-i18next";

export default function Users() {
  const { t } = useTranslation();
  const { accountData, user } = useAuth();
  const [users, setUsers] = useState();
  const [expanded, setExpanded] = React.useState(false);
  const [canAdd, setCanAdd] = useState(true);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState();

  const getUsers = async () => {
    setErrorMessage(null);
    await usersService.getUsers().then(res => {
      setUsers(res);
    });
  };

  const enableAdd = user => {
    console.log("enableAdd");
    setCanAdd(true);

    let clone = users.filter(p => !(p.id === null || p.id === undefined));
    if (user) {
      clone.unshift(user);
    }
    setUsers(clone);
  };

  const deleteUser = user => {
    console.log("deleteUser");
    if (user) {
      let clone = users.filter(p => p.id !== user.id);
      setUsers(clone);
    }
  };

  const addUser = async e => {
    const id = e.target.id;
    console.log("add user");
    setCanAdd(false);
    const clone = [...users];
    clone.unshift({ name: "", active: false });
    setUsers(clone);
  };

  useEffect(() => {
    (async () => {
      try {
        await getUsers();
      } catch (error) {
        console.log(error);
        setErrorMessage(t("users.errMess"));
      } finally {
        setLoading(false);
      }
    })();
  }, []);
  return (
    <Box
      pt={2}
      sx={{
        background: accountData.settings.bodyBackground,
        height: "calc(98vh - 65px)",
        overflow: "auto",
      }}
    >
      <Container maxWidth="md">
        <Grid container>
          <Grid item xs={10}>
            <Typography mb={2} variant="h6">
              {t("users.user")}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" justifyContent="flex-end" mr={2}>
              <Tooltip title={t("users.newUser")}>
                <Fab size="small" color="primary" aria-label="add" onClick={addUser} disabled={!canAdd}>
                  <AddIcon />
                </Fab>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
        {loading && <CircularProgress />}
        {errorMessage && (
          <Grid item xs={12}>
            <Alert severity="error">{errorMessage}</Alert>
          </Grid>
        )}
        {users &&
          users.map(user => {
            return <UserItem key={user.id} item={user} onAddItem={enableAdd} onDeleteItem={deleteUser}></UserItem>;
          })}{" "}
      </Container>
    </Box>
  );
}
