import axios from "axios";

const getUserActionById = async id => {
  const response = await axios.get(`/User-Actions/${id}`);
  return response.data;
};

const createUserAction = async payload => {
  const response = await axios.post(`/User-Actions`, payload);
  return response.data;
};

export default {
  getUserActionById,
  createUserAction,
};
