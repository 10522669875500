import React, { useState, useEffect, useRef } from "react";
import projectsService from "../../services/projects-service";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { Chip, Button, Grid, Stack, Switch, TextField, Alert, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import DeleteButton from "../../components/DeleteButton";
import { useTranslation } from "react-i18next";

export default function ProjectItem(props) {
  const { t } = useTranslation();
  const { item, onAddItem, onDeleteItem } = props;
  const [activeChecked, setActiveChecked] = useState(item.active);
  const [project, setProject] = useState(item);
  const [errorMessage, setErrorMessage] = useState();
  const [expanded, setExpanded] = useState(false);
  const [newItem, setNewItem] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const nameInput = useRef(null);

  const setNameFocus = () => {
    if (nameInput.current) {
      nameInput.current.focus();
    }
  };

  const handleAccordionChange = e => {
    setExpanded(!expanded);
  };

  const setFormValid = () => {
    setCanSave(project.name ? true : false);
  };

  const handleChange = e => {
    setErrorMessage(null);
    setFormValid();
    const name = e.target.name;
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setProject({ ...project, [name]: value });
  };

  const handleActiveChanged = e => {
    console.log("handleActiveChanged");
    console.log(e);
    const value = e.target.checked;
    console.log(`name: value: ${value}`);
    setProject({ ...project, active: value });
  };

  const saveProject = async () => {
    console.log("save project");
    projectsService
      .save(project)
      .then(p => {
        setExpanded(false);
        if (newItem) onAddItem(p);
      })
      .catch(e => {
        setErrorMessage(e.message ? e.message : e);
      });
  };

  const deleteProject = async () => {
    console.log("delete project");
    projectsService
      .deleteProject(project.id)
      .then(p => {
        if (onDeleteItem) onDeleteItem(project);
      })
      .catch(e => {
        setErrorMessage(e.message ? e.message : e);
      });
  };

  const cancelSave = () => {
    if (newItem) onAddItem();
    setExpanded(false);
  };

  useEffect(() => {
    if (project.id === null || project.id === undefined) {
      setExpanded(true);
      setNewItem(true);
    }
    setFormValid();
    setNameFocus();
  }, []);
  return (
    <Accordion key={project.id} expanded={expanded} onChange={handleAccordionChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={project.id} id={project.id}>
        <Grid container>
          <Grid item xs={10}>
            {!newItem && project.name && (
              <Typography mr={4}>
                <strong>{project.name}</strong>
              </Typography>
            )}
            {newItem && (
              <Typography mr={4} color="primary.main">
                <strong>{t("projectItem.new")}</strong>
              </Typography>
            )}
          </Grid>
          {!project.active && (
            <Grid item xs={2}>
              <Box display="flex" justifyContent="flex-end" mr={1}>
                {!project.active && (
                  <Chip
                    label={t("projectItem.notActive")}
                    sx={{ background: "gray", color: "whitesmoke" }}
                    size="small"
                  />
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      </AccordionSummary>

      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <TextField
              fullWidth
              ref={nameInput}
              size="small"
              id="outlined-basic"
              label={t("projectItem.name")}
              name="name"
              defaultValue={project.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Stack>
              <div>
                <span className="detailLabel">{t("projectItem.active")}</span>
                <Switch defaultChecked={activeChecked} onChange={handleActiveChanged} />
              </div>
            </Stack>
          </Grid>
          <Grid item xs={12} md={10}>
            <Stack direction="row" spacing={2}>
              <Button onClick={saveProject} size="small" variant="contained" disabled={!canSave}>
                {t("projectItem.save")}
              </Button>
              {newItem && (
                <Button onClick={cancelSave} size="small" variant="contained" sx={{ background: "#6c757d" }}>
                  {t("projectItem.undo")}
                </Button>
              )}
              {!newItem && (
                <DeleteButton
                  itemId={item.id}
                  text={t("projectItem.delete")}
                  onClick={deleteProject}
                  color="error"
                ></DeleteButton>
              )}
            </Stack>
          </Grid>
          {errorMessage && (
            <Grid item xs={12}>
              <Alert severity="error">{errorMessage}</Alert>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
