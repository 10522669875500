import React, { useState, useEffect } from "react";
import { btnColor } from "../../Accounts-styles";
import { useNavigate, useParams } from "react-router";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Container,
  Button,
  Alert,
  AlertTitle,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { useAuth } from "../../context/AuthContext";
import { useSearchParams } from "react-router-dom";
import authService from "../../services/auth-service";
import usersService from "../../services/users-service";
import userActionService from "../../services/userAction-service";
import accountsService from "../../services/accounts-service";
import userActionHelper from "../../services/user-action-helper";
import Header from "../../components/Header";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import createManifest from "../../services/pwa-manifest-helper";
import { useTranslation } from "react-i18next";

export default function ActivateUser() {
  const { t } = useTranslation();
  const { account } = useParams();
  const { accountData, setAccountData, token, setToken } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const navigate = useNavigate();
  const [payload, setPayload] = useState({ active: true });
  const [showError, setShowError] = useState({ message: "", display: false });
  const [btnSpinner, setBtnSpinner] = useState(false);
  const [userActivated, setUserActivated] = useState(false);
  const [userEmail, setUserEmail] = useState();
  const [activateUserRequested, setActivateUserRequested] = useState(false);
  const [canRequest, setCanRequest] = useState(false);

  const getAccount = async () => {
    return await accountsService.getAccount(account);
  };

  const activateUser = async () => {
    setBtnSpinner(true);
    try {
      const action = await userActionService.getUserActionById(id);
      console.log("activateUser", action.token);
      const actionUser = authService.decodeToken(action.token);
      console.log("activateUser", actionUser.userId);
      const res = usersService.activateUser(actionUser.userId, payload);
      console.log("activateUser", res.id);
      authService.setToken(action.token);
      setUserActivated(true);
      setBtnSpinner(false);
    } catch (error) {
      setBtnSpinner(false);
      setShowError({ display: true, message: t("activateUser.errMess_1") });
    }
  };

  const sendActivationLink = () => {
    console.log("sendActivationLink");
    setCanRequest(false);

    setShowError({ message: "", display: false });
    const userAction = userActionHelper.getActivateUserAction(account, userEmail);
    console.log("sendActivationLink", userAction);
    userActionService
      .createUserAction(userAction)
      .then(action => setActivateUserRequested(true))
      .catch(error => {
        console.log("resendActivationLink failed", error);
        setShowError({ display: true, message: t("activateUser.errMess_2") });
      });
  };

  const handleChange = e => {
    console.log("handleChange", e.target.value);
    const value = e.target.value;
    if (value) {
      console.log("handleChange!", value);
      if (
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value
        ) === false
      )
        return;
      setUserEmail(value);
      setCanRequest(true);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        activateUser();
        const a = await getAccount();
        setAccountData(a);
        createManifest(a);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  return (
    <>
      <Header />
      {accountData && (
        <Box pt={2} sx={{ background: accountData.settings.bodyBackground, height: "90vh" }}>
          <Container
            maxWidth="xs"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Container sx={{ alignSelf: "center", marginTop: "40px" }}>
              <Typography mb={2} variant="h6">
                {t("activateUser.actAccount")}
              </Typography>
              <Stack spacing={1}>
                {btnSpinner && <CircularProgress size={17} />}
                {userActivated && (
                  <>
                    <Alert severity="success">{t("activateUser.alert_1")}</Alert>
                    <Button fullWidth={true} variant="contained" onClick={() => navigate(`/${account}`)}>
                      {t("activateUser.start")}
                    </Button>
                  </>
                )}
                {activateUserRequested && (
                  <>
                    <Alert severity="success">{t("activateUser.alert_2")}</Alert>
                    <Button
                      variant="outlined"
                      sx={{ borderColor: btnColor, color: btnColor }}
                      onClick={() => navigate(`/${account}/Login`)}
                    >
                      {t("activateUser.toLogin")}
                    </Button>
                  </>
                )}
                {showError.display && (
                  <>
                    <Alert severity="error">
                      <AlertTitle>{t("activateUser.alert_3")}</AlertTitle>
                      {showError.message}
                    </Alert>

                    <Accordion sx={{ background: accountData.settings.bodyBackground }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>{t("activateUser.sendActLink")}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack spacing={1}>
                          <TextField
                            fullWidth={true}
                            autoComplete="off"
                            id="filled-search"
                            label={t("activateUser.label")}
                            variant="filled"
                            onChange={handleChange}
                          />

                          <Button
                            fullWidth={true}
                            type="submit"
                            variant="contained"
                            sx={{ marginTop: "20px", background: btnColor }}
                            onClick={sendActivationLink}
                            disabled={!canRequest}
                          >
                            {t("activateUser.send")}
                          </Button>
                          <Alert severity="info">{t("activateUser.alert_4")}</Alert>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  </>
                )}
              </Stack>
            </Container>
          </Container>
        </Box>
      )}
    </>
  );
}
