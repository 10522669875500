import axios from "axios";

const getProcessSteps = async () => {
  const response = await axios.get(`/process`);
  return response.data;
};

export default {
  getProcessSteps,
};
