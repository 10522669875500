import React, { useState, useEffect } from "react";
import axios from "axios";
import { Routes, Route } from "react-router-dom";

import AuthProvider from "./context/AuthContext";

import LandingPage from "./pages/Lp";
import Main from "./pages/Main";
import ScannerPage from "./pages/ScannerPage";
import Users from "./pages/users/Users";
import Projects from "./pages/projects/Projects";
import Suppliers from "./pages/suppliers/Suppliers";
import Stands from "./pages/stands/Stands";
import StandsDashboard from "./pages/stands/Dashboard";
import StandsList from "./pages/stands/List";
import UserSettings from "./pages/users/UserSettings";
import Stand from "./pages/stand/Stand";
import StandConfirm from "./pages/stand/StandConfirm";
import StandCreate from "./pages/stand/StandCreate";
import ResetPassword from "./pages/user-actions/ResetPassword";
import ForgotPassword from "./pages/user-actions/ForgotPassword";
import ActivateUser from "./pages/users/ActivateUser";
import RegisterUser from "./pages/users/RegisterUser";
import Login from "./pages/Login";
import authService from "./services/auth-service";

const App = () => {
  console.log("App!");
  console.log(window.location.pathname); //yields: "/js" (where snippets run)
  console.log(window.location.href);
  console.log(process.env.REACT_APP_WEB); //printing it to console

  axios.interceptors.request.use(function (config) {
    config.baseURL = process.env.REACT_APP_API;
    const token = authService.getToken();
    config.headers.Authorization = token;
    return config;
  });

  const getAccountFromPath = () => {
    try {
      const path = window.location.pathname.toLocaleLowerCase();
      const parts = path.split("/");
      return parts[1];
    } catch (error) {
      console.log("getAccountFromPath failed", error);
      return null;
    }
  };

  const doAxiosIntercept = () => {
    try {
      const path = window.location.pathname.toLowerCase();
      const exceptionPaths = ["/login", "/activateuser", "/resetpassword", "/forgotpassword", "/registeruser"];
      const exception = exceptionPaths.find(x => path.endsWith(x));
      console.log("doAxiosIntercept", path, exception);
      if (exception) {
        console.log("doAxiosIntercept", path, "exception!");
        return false;
      } else {
        console.log("doAxiosIntercept", path, "NO exceptionen");
        return true;
      }
    } catch (error) {
      console.log("doAxiosIntercept failed", error);
      return false;
    }
  };

  if (doAxiosIntercept()) {
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        try {
          if (401 === error.response.status) {
            authService.logout();
            const account = getAccountFromPath();
            console.log("axios 401", account);
            if (account === null || account === "") {
              window.location = `/`;
            } else {
              window.location = `/${account}/login`;
            }

            return Promise.reject(error);
          } else {
            return Promise.reject(error);
          }
        } catch (err) {
          console.log("axios intercept failed", err);
          return Promise.reject(error);
        }
      }
    );
  }

  return (
    <>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path=":account/forgotpassword" element={<ForgotPassword />} />
          <Route path=":account/resetpassword" element={<ResetPassword />} />
          <Route path=":account/activateuser" element={<ActivateUser />} />
          <Route path=":account/registeruser" element={<RegisterUser />} />
          <Route path=":account/login" element={<Login />} />
          <Route path=":account" element={<Main />}>
            <Route path="" element={<ScannerPage />} />
            <Route path=":id" element={<Stand />} />
            <Route path=":id/confirm" element={<StandConfirm />} />
            <Route path=":id/create" element={<StandCreate />} />
            <Route path="users" element={<Users />} />
            <Route path="projects" element={<Projects />} />
            <Route path="suppliers" element={<Suppliers />} />
            <Route path="stands" element={<Stands />} />
            <Route path="stands/dashboard" element={<StandsDashboard />} />
            <Route path="stands/list" element={<StandsList />} />
            <Route path="settings" element={<UserSettings />} />
            <Route path="resetpassword" element={<ResetPassword />} />
          </Route>
        </Routes>
      </AuthProvider>
    </>
  );
};

export default App;
