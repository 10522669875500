import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import Scanner from "../components/Scanner2";
import { btnColor } from "../Accounts-styles";
import {
  Box,
  Container,
  TextField,
  InputLabel,
  FilledInput,
  FormControl,
  Button,
  Alert,
  Stack,
  CircularProgress,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import accountsService from "../services/accounts-service";
import standsService from "../services/stands-service";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAuth } from "../context/AuthContext";
import { useTranslation } from "react-i18next";

export default function ScannerPage() {
  const { t } = useTranslation();
  const { account } = useParams();
  const { accountData } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [standNr, setStandNr] = useState();
  const [noStand, setNoStand] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const getStands = async () => {
    setLoading(true);
    setNoStand(false);
    setErrorMessage(false);
    if (standNr) {
      standsService
        .getStandsByVisualNr(standNr)
        .then(res => {
          if (res.length >= 1) {
            navigate(`/${account}/${standNr}`);
          } else {
            setNoStand(true);
          }
        })
        .catch(error => {
          console.log("Failed to get stand", error);
          setErrorMessage(t("scannerPage.errMess"));
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      {accountData && (
        <Box
          pt={1}
          mt={0}
          sx={{
            background: accountData.settings.bodyBackground,
            height: "calc(100vh)",
            overflow: "auto",
          }}
        >
          <Container
            maxWidth="xs"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Scanner />
            <Container sx={{ alignSelf: "center", mt: "4px"}}>
              <Accordion  sx={{ background: accountData.settings.bodyBackground }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography>{t("scannerPage.manSearch")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack spacing={1}>
                    <TextField
                      fullWidth={true}
                      autoComplete="off"
                      id="filled-search"
                      label={t("scannerPage.searchPlaceholder")}
                      type="search"
                      variant="filled"
                      onChange={e => setStandNr(e.target.value)}
                    />

                    <Button
                      fullWidth={true}
                      type="submit"
                      variant="contained"
                      startIcon={<SearchRoundedIcon />}
                      sx={{ marginTop: "20px", background: btnColor }}
                      onClick={getStands}
                    >
                      {t("scannerPage.searchBtn")}
                    </Button>
                    {!noStand && (
                      <Button
                        variant="outlined"
                        sx={{ borderColor: btnColor, color: btnColor }}
                        onClick={() => navigate(`0/create`)}
                      >
                        {t("scannerPage.regStand")}
                      </Button>
                    )}
                  </Stack>
                  {noStand && (
                    <Stack mt={2} sx={{ width: { xs: "100%" } }} spacing={1}>
                      {loading && <CircularProgress />}
                      <Alert severity="error">
                        {t("scannerPage.noStandMess")}
                        <Button size="small" variant="contained" onClick={() => navigate(`${standNr}/create`)}>
                          {t("scannerPage.regStand")}
                        </Button>
                      </Alert>
                    </Stack>
                  )}
                  {errorMessage && (
                    <Stack mt={2} sx={{ width: { xs: "100%" } }}>
                      <Alert severity="error">
                        {t("scannerPage.noStandMess")}
                        <Button size="small" variant="contained" onClick={() => navigate(`${standNr}/create`)}>
                          {t("scannerPage.reg")}
                        </Button>
                      </Alert>
                    </Stack>
                  )}
                </AccordionDetails>
              </Accordion>
            </Container>
          </Container>
        </Box>
      )}
    </>
  );
}
