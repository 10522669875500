import React, { useState, useEffect } from "react";
import service from "../../services/stands-service";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Chip, Grid, Stack, Fab } from "@mui/material";
import { Box } from "@mui/system";
import StandHistory from "./StandHistory";
import DeleteButton from "../../components/DeleteButton";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";

export default function StandItem(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const { item, step, onDeleteItem } = props;
  const [stand, setStand] = useState(item);
  const [errorMessage, setErrorMessage] = useState();
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = e => {
    setExpanded(!expanded);
  };

  const deleteStand = async () => {
    console.log("delete stand");
    service
      .deleteStand(stand.id)
      .then(p => {
        if (onDeleteItem) onDeleteItem(stand);
      })
      .catch(e => {
        setErrorMessage(e.message ? e.message : e);
      });
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => { }, []);
  return (
    <Accordion key={stand.id} expanded={expanded} onChange={handleAccordionChange}>
      <AccordionSummary mb={0} pb={0} expandIcon={<ExpandMoreIcon />} aria-controls={stand.id} id={stand.id}>
        <Grid container>
          <Grid item xs={6} md={3}>
            <Typography mr={4}>
              <strong>{stand.number}</strong>
            </Typography>
          </Grid>
          <Grid item xs={6} md={9}>
            {stand.supplier}
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails mb={0} pb={0}>
        <Grid container justifyContent="left" columns={12} alignItems="flex-start">
          <Grid item xs={10} md={8} mb={0}>
            <Grid container justifyContent="left" columns={12} alignItems="flex-start">
              <Grid item xs={12} md={8} mt={0}>
                <Stack mt={0} spacing={0}>
                  <Box component="span">
                    <Typography component="span" className="detailLabel">
                      {t("standItem.supplier")}:
                    </Typography>
                    <Typography component="span">{stand.supplier}</Typography>
                  </Box>
                  {stand.project && (
                    <Box component="span">
                      <Typography component="span" className="detailLabel">
                        {t("standItem.project")}:
                      </Typography>
                      <Typography component="span">{stand.project}</Typography>
                    </Box>
                  )}
                  {stand.transportReference && (
                    <Box component="span">
                      <Typography component="span" className="detailLabel">
                        {t("standItem.car")}:
                      </Typography>
                      <Typography component="span">{stand.transportReference}</Typography>
                    </Box>
                  )}
                  {stand.orderReference && (
                    <Box component="span">
                      <Typography component="span" className="detailLabel">
                        {t("standItem.order")}:
                      </Typography>
                      <Typography component="span">{stand.orderReference}</Typography>
                    </Box>
                  )}
                  {stand.delayed && (
                    <Box component="span">
                      <Typography component="span" className="detailLabel">
                        {t("standItem.delayed")}:
                      </Typography>
                      <Typography component="span"><Moment format="D MMM YYYY">{stand.delayed}</Moment></Typography>
                    </Box>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={4} mt={1}>
                <Stack spacing={1} direction="column" alignItems="center">
                  <Box display="flex" justifyContent="flex-start" mt={0}>
                    {stand.active === false && (
                      <Chip label={t("standItem.inactive")} sx={{ background: "gray", color: "whitesmoke" }} size="small" />
                    )}
                    {stand.active && stand.stepDescription && (
                      <Chip label={stand.stepDescription} color="primary" variant="contained" size="small" />
                    )}
                  </Box>

                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={2} md={4}
            mb={0}
            container
            direction="column"
            alignItems="flex-end"
            justify="flex-start" //in MUI v5 this prop is renamed justifyContent
          >
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'stand-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="stand-menu"
              open={open}
              onClose={handleClose}
              //onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <DeleteButton
                  itemId={stand.id}
                  text={t("standItem.delete")}
                  onClick={deleteStand}
                  color="error"
                  variant="text"
                ></DeleteButton>
              </MenuItem>
            </Menu>
          </Grid>

          <Grid item xs={12} mt={1}>
            <StandHistory stand={stand}></StandHistory>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
