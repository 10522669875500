import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import accountsService from "../services/accounts-service";

import { Toolbar, Typography, AppBar, Stack, MenuItem, IconButton, Menu, Button, Container, Grid } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";

import { useTranslation, Trans } from "react-i18next";
import { Box } from "@mui/system";

const lngs = {
  se: { nativeName: "Svenska" },
  en: { nativeName: "English" },
};

export default function Header() {
  const { t, i18n } = useTranslation();
  const { account } = useParams();
  const [accountData, setAccountData] = useState();
  const navigate = useNavigate();
  const [menu, setMenu] = useState(null);

  const getAccount = async () => {
    await accountsService.getAccount(account).then(res => {
      setAccountData(res);
    });
  };
  useEffect(() => {
    (async () => {
      getAccount();
    })();
  }, []);
  return (
    <>
      {accountData && (
        <AppBar
          position="static"
          sx={{
            background: accountData.settings.headerColor,
            height: "65px",
          }}
        >
          <Toolbar>
            <Box display='flex' flexGrow={1} alignItems="center">
              <Typography
                pt={1}
                variant="h5"
                noWrap
                component="div"
                onClick={() => navigate(`/${account}`)}
              >
                <img
                  style={{
                    width: "auto",
                    height: "40px",
                  }}
                  alt="logo"
                  src={accountData.settings.logo}
                />
              </Typography>
              {accountData.settings.logoTitle && (
                <Typography pl={2} variant="h5">
                  {accountData.settings.logoTitle}
                </Typography>
              )}
            </Box>

            <Stack direction="row" alignItems="center" spacing={1}>
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-controls="menu"
                aria-haspopup="true"
                onClick={e => setMenu(e.currentTarget)}
                aria-label="menu"
              >
                <LanguageIcon />
              </IconButton>
            </Stack>
            <Menu
              id="menu"
              anchorEl={menu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              keepMounted
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              open={Boolean(menu)}
              onClose={() => setMenu(null)}
              onClick={() => setMenu(null)}
            >
              <MenuItem>
                <Stack>
                  {Object.keys(lngs).map(lng => (
                    <Button
                      key={lng}
                      style={{ fontWeight: i18n.resolvedLanguage === lng ? "bold" : "normal" }}
                      type="submit"
                      onClick={() => i18n.changeLanguage(lng)}
                    >
                      {lngs[lng].nativeName}
                    </Button>
                  ))}
                </Stack>
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
}
