import { createContext, useContext, useState } from "react";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

const AuthProvider = props => {
  const [token, setToken] = useState();
  const [accountData, setAccountData] = useState();
  const [user, setUser] = useState();

  return (
    <>
      <AuthContext.Provider value={{ token, setToken, accountData, setAccountData, user, setUser }}>
        {props.children}
      </AuthContext.Provider>
    </>
  );
};

export default AuthProvider;
