export default function createManifest(account){
  try {
    if (account === null || account === undefined) { 
      console.log('createManifest. no account'); 
      return;
    }

    console.log('createManifest');
    const background = account.settings.bodyBackground ? account.settings.bodyBackground : '#ffffff';
    let manifest = {
      "name": `${account.name} bockat.se`,
      "short_name": `${account.name}`,
      "icons": [
        { src: "https://bockat.se/favicon.ico", sizes: "48x48", type: "image/x-icon" },
        { src: "https://bockat.se/logo192.png", sizes: "192x192", type: "image/png" },
        { src: "https://bockat.se/logo512.png", sizes: "512x512", type: "image/png" }
      ],
      "start_url": `https://bockat.se/${account.id}`,
      "display": "standalone",
      "theme_color": "#000000",
      "background_color": `${background}`,
      "orientation": "portrait-primary",
      "scope": "https://bockat.se/"
    };

    let content = encodeURIComponent(JSON.stringify(manifest));
    let url = "data:application/manifest+json," + content;
    let element = document.createElement('link');
    element.setAttribute('rel', 'manifest');
    element.setAttribute('href', url);
    document.querySelector('head').appendChild(element);
    console.log('createManifest done');

  } catch (error) {
    console.log('createManifest failed', error);
  }
}
