import React, { useState, useEffect } from "react";
import { Alert, Box, Button, Container, Grid, Stack, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import logo from "../images/Bockat_logo_dark.png";
import phoneIcon from "../images/phone_icon.png";
import contactIcon from "../images/contact_icon.png";
import "@fontsource/jost";
import { useNavigate } from "react-router";
import notificationsService from "../services/notifications-service";
import { Fade, Slide, AttentionSeeker } from "react-awesome-reveal";

export default function Lp() {
  const navigate = useNavigate();
  const [canSend, setCanSend] = useState(false);
  const [sendContactSuccess, setSendContactSuccess] = useState(false);
  const [sendContactError, setSendContactError] = useState(false);
  const [contact, setContact] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const setFormValid = data => {
    console.log("setFormValid", data);
    if (data.email.length > 1) {
      if (
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          data.email
        ) === false
      ) {
        console.log("No mail!", data.email);
        setCanSend(false);
        return;
      }
    } else if (data.phone.length < 1) {
      console.log("No phone!");
      setCanSend(false);
      return;
    }

    if (data.name.length < 1) {
      console.log("No name!");
      setCanSend(false);
      return;
    }
    setCanSend(true);
  };

  const handleChange = e => {
    const name = e.target.id;
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const data = { ...contact, [name]: value };

    setContact(data);
    setFormValid(data);
  };

  const openInNewTab = url => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const sendContact = async () => {
    let body = `Kontaktuppgifter:\n`;
    for (const property in contact) {
      body += `${property}: ${contact[property]}\n`;
    }
    console.log("sendContact", body);
    const notification = { subject: "Bockat - Kontakt", body: body };
    notificationsService
      .sendNotification(notification)
      .then(result => setSendContactSuccess(true))
      .catch(error => {
        console.log("sendContact failed", error);
        setSendContactError(true);
      });
  };

  useEffect(() => {
    setFormValid(contact);
  }, []);
  return (
    <>
      <div style={{ width: "100vw", height: "100vh", overflow: "auto" }}>
        <Container maxWidth="sm">
          <Grid pt={6} direction="row" container spacing={2} alignItems="flex-end" justifyContent="space-evenly">
            <Slide triggerOnce={true}>
              <img style={{ width: "140px" }} src={logo} />
            </Slide>
            <Slide triggerOnce={true} direction="right">
              <Typography pb={0.8} textAlign="center" sx={{ fontFamily: "Jost", color: "#333333" }} variant="h4">
                Tracking your glass stands
              </Typography>
            </Slide>
          </Grid>
        </Container>
        <Container
          maxWidth="sm"
          sx={{ height: "30px", borderBottom: "2px dotted #333333", marginBottom: "20px" }}
        ></Container>
        <Fade duration={4000}>
          <Grid container direction="row" justifyContent="center" columns={{ xs: 1, sm: 1, md: 2 }}>
            <Stack
              p={4}
              m={2}
              spacing={2}
              alignItems="center"
              justifyContent="center"
              sx={{ background: "#e6e6e6", borderRadius: "5px" }}
            >
              <img style={{ width: "60px" }} src={phoneIcon} />

              <Typography
                sx={{ fontFamily: "Jost", color: "#333333", maxWidth: "280px" }}
                textAlign="center"
                variant="h6"
              >
                DEMO
                <br />
                Try our demo. Register an account and scan your stand
                <br />
                Contact us for a full presentation of the system.
              </Typography>
              <AttentionSeeker triggerOnce={true} delay={5000} effect="pulse">
                <Button
                  pt={4}
                  variant="contained"
                  sx={{ background: "#f7931e", borderRadius: "20px" }}
                  onClick={() => openInNewTab("https://bockat.se/demo")}
                >
                  Go to demo
                </Button>
              </AttentionSeeker>
            </Stack>

            <Stack p={4} m={2} sx={{ background: "#e6e6e6", borderRadius: "5px" }}>
              {!sendContactSuccess && !sendContactError && (
                <Stack alignItems="center" justifyContent="center" textAlign="center" spacing={2}>
                  <img style={{ width: "60px" }} src={contactIcon} />

                  <Typography sx={{ fontFamily: "Jost", color: "#333333", maxWidth: "280px" }} variant="h6">
                    CONTACT US
                    <br /> Leave your information and we will contact you.
                  </Typography>

                  <TextField fullWidth id="name" label="Name" variant="standard" onChange={handleChange} />
                  <TextField fullWidth id="phone" label="Phone" variant="standard" onChange={handleChange} />
                  <TextField fullWidth id="email" label="Email" variant="standard" onChange={handleChange} />
                  <TextField
                    fullWidth
                    id="standard-multiline-static"
                    label="Message"
                    multiline
                    rows={1}
                    defaultValue=""
                    variant="standard"
                    onChange={handleChange}
                  />

                  <Button
                    variant="contained"
                    sx={{ background: "#f7931e", borderRadius: "20px" }}
                    onClick={sendContact}
                    disabled={!canSend}
                  >
                    Send
                  </Button>
                </Stack>
              )}
              {sendContactSuccess && (
                <Stack spacing={1}>
                  <Alert
                    severity="success"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setSendContactError(false);
                          setSendContactSuccess(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    Thank you!
                  </Alert>
                </Stack>
              )}
              {sendContactError && (
                <Stack spacing={1}>
                  <Alert
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setSendContactError(false);
                          setSendContactSuccess(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    Unfortunately we couldnt save your contactinformation, please try again later :(
                  </Alert>
                </Stack>
              )}
            </Stack>
          </Grid>
        </Fade>
      </div>
    </>
  );
}
