import axios from "axios";
import jwt_decode from "jwt-decode";

let token = null;

const authHeader = () => {
  const token = localStorage.getItem("token");

  if (token) {
    return {
      Authorization: "Bearer " + token,
    };
  } else {
    return {};
  }
};

const login = async payload => {
  const response = await axios.post(process.env.REACT_APP_API_SECURITY, payload);
  if (response.data.token) {
    token = response.data.token;
    localStorage.setItem("token", token);
  }
  return response.data;
};

const setToken = (t) =>{
  token = t;
  localStorage.setItem("token", token);
}

const logout = () => {
  token = null;
  localStorage.removeItem("token");
};

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

const getUser = () => {
  var token = getToken();
  if (token) {
    var payload = jwt_decode(token);

    return payload;
  }
  return null;
};

const decodeToken = (token) => {
  try {
    return jwt_decode(token);
  } catch (error) {
    console.log('failed to parse token', error);
    return null;
  }
};

const saveUsername = (username) =>{
  localStorage.setItem("u", username);
}

const clearUsername = () =>{
  localStorage.removeItem("u");
}

const getUsername = () =>{
  return localStorage.getItem("u");
}

const isActive = () => {
  if (token === null) {
    console.log("token null.. try get from storage");
    token = getToken();
  }
  return token !== null;
};

export default {
  authHeader,
  login,
  logout,
  getToken,
  setToken,
  getUser,
  isActive,
  saveUsername,
  getUsername,
  clearUsername,
  decodeToken
};
