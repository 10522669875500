import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import { useTranslation } from "react-i18next";

export default function DashboardItem(props) {
  const { t } = useTranslation();
  const { item } = props;
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    console.log('item', item);
  }, []);
  return (

    <Box
      p={2}
      alignItems="center"
      justifyContent="center"
      sx={{
        display: 'flex',
        m: 1,
        backgroundColor: '#005AFF',
        '&:hover': {
          backgroundColor: 'primary.main',
          opacity: [0.9, 0.8, 0.7],
        },
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}>
        <Typography variant="h5" component="div" color="common.white" sx={{ textTransform: 'uppercase' }}>
          {item.description}
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          spacing={3}>
          <Typography variant="h6" component="div" color="common.white" sx={{ textTransform: 'uppercase', fontWeight: 600 }}>
            {item.count}
          </Typography>
          {item.warningCount > 0 && (
            <Tooltip title={t("dashboardItem.delayed")}>
            <Typography variant="body1" component="div" color="common.white" sx={{ textTransform: 'uppercase' }}>
              <Chip key={item.step} color='primary' style={{backgroundColor:'#ff9800'}} label={item.warningCount} />
            </Typography>
            </Tooltip>
          )}

        </Stack>

      </Stack>
    </Box>
  );
}
