import React, { useState, useEffect } from "react";
import { btnColor } from "../../Accounts-styles";
import { Navigate, useNavigate, useParams } from "react-router";

import LoginIcon from "@mui/icons-material/Login";
import { Password } from "@mui/icons-material";
import {
  Box,
  Container,
  Button,
  TextField,
  Stack,
  Icon,
  Alert,
  AlertTitle,
  CircularProgress,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import usersService from "../../services/users-service";

import authService from "../../services/auth-service";
import { useAuth } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";

export default function UserSettings() {
  const { t } = useTranslation();
  const { accountData } = useAuth();
  const [password, setPassword] = useState();
  const [passwordCheck, setPasswordCheck] = useState();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRepeatNewPassword, setShowRepeatNewPassword] = useState(false);
  const [user, setUser] = useState();
  const [btnSpinner, setBtnSpinner] = useState(false);
  const [showErrMess, setShowErrMess] = useState({ display: false, status: "" });
  const [showSuccess, setShowSuccess] = useState(false);
  const [payload, setPayload] = useState({
    password: "",
  });
  const getUser = async () => {
    const user = authService.getUser();
    setUser(user);
  };

  const savePassword = async () => {
    setBtnSpinner(true);
    if (passwordCheck === payload.password) {
      await usersService
        .changePassword(payload)
        .then(() => {
          setShowSuccess(true);
          setBtnSpinner(false);
        })
        .catch(error => {
          if (error.response) {
            setShowErrMess({ display: true, status: "status: " + error.response.status });
            setBtnSpinner(false);
          }
        });
    }
  };

  useEffect(() => {
    getUser();
  }, []);
  return (
    <>
      <Box pt={2} sx={{ background: accountData.settings.bodyBackground, height: "90vh" }}>
        <Container
          maxWidth="xs"
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Container sx={{ alignSelf: "center", marginTop: "40px" }}>
            <Typography mb={2} variant="h6">
              {t("userSettings.accSettings")}
              {user && (
                <>
                  <Typography variant="body1">
                    {t("userSettings.name")}: {user.name}
                  </Typography>
                  <Typography variant="body1">
                    {t("userSettings.email")}: {user.email}
                  </Typography>
                </>
              )}
            </Typography>
            <Stack spacing={2}>
              <TextField
                onChange={e => setPayload({ ...payload, password: e.target.value })}
                fullWidth
                id="outlined-basic"
                label={t("userSettings.labelPassword")}
                variant="outlined"
                type={showNewPassword ? "text" : "password"}
                InputProps={{
                  autoComplete: "new-email",
                  endAdornment: (
                    <inputAdornmentClasses position="end">
                      <Icon
                        sx={{ cursor: "pointer" }}
                        aria-label="toggle password visibility"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        onMouseDown={() => setShowNewPassword(!showNewPassword)}
                      >
                        {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </Icon>
                    </inputAdornmentClasses>
                  ),
                }}
              />
              <TextField
                label={t("userSettings.labelRepeat")}
                fullWidth
                variant="outlined"
                type={showRepeatNewPassword ? "text" : "password"}
                onChange={e => setPasswordCheck(e.target.value)}
                InputProps={{
                  autoComplete: "new-email",
                  endAdornment: (
                    <inputAdornmentClasses position="end">
                      <Icon
                        sx={{ cursor: "pointer" }}
                        aria-label="toggle password visibility"
                        onClick={() => setShowRepeatNewPassword(!showRepeatNewPassword)}
                        onMouseDown={() => setShowRepeatNewPassword(!showRepeatNewPassword)}
                      >
                        {showRepeatNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </Icon>
                    </inputAdornmentClasses>
                  ),
                }}
              />
            </Stack>
            <Button
              fullWidth
              variant="contained"
              sx={{ marginTop: "20px", background: btnColor }}
              onClick={savePassword}
            >
              {t("userSettings.save")}&nbsp;
              {btnSpinner && <CircularProgress size={17} sx={{ color: "white" }} />}
            </Button>

            <Box mt={2}>
              {showSuccess && (
                <Alert severity="success">
                  <AlertTitle>{t("userSettings.alertTitle_1")}</AlertTitle>
                </Alert>
              )}
              {showErrMess.display && (
                <Alert severity="error">
                  <AlertTitle>{t("userSettings.alertTitle_2")}</AlertTitle>
                  Error {showErrMess.status}
                </Alert>
              )}
            </Box>
          </Container>
        </Container>
      </Box>
    </>
  );
}
