import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../context/AuthContext";
import service from "../../services/stands-service";
import {
  Alert,
  CircularProgress,
  Grid,
  Stack
} from "@mui/material";
import { Box } from "@mui/system";
import DashboardItem from "./DashboardItem";
import { useTranslation } from "react-i18next";

export default function Dashboard(props) {
  const { t } = useTranslation();
  const { accountData } = useAuth();
  const [dashboard, setDashboard] = useState();
  const { filter } = props;
  const [filterApplied, setFilterApplied] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState();

  const getDashboard = async () => {
    setErrorMessage(null);
    setLoading(true);
    await service
      .getDashboard(filter)
      .then(res => {
        console.log("getDashboard", res);
        setDashboard(res.slice(0, -1));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    console.log('dashboard. useEffect ', props);
    (async () => {
      try {
        getDashboard();
      } catch (error) {
        console.log(error);
        setErrorMessage(t("stands.getStandFail"));
      } finally {
        setLoading(false);
      }
    })();
  }, [props.reload]);
  return (
    <Stack spacing={0}>
      {loading && <CircularProgress />}
      {errorMessage && (
        <Grid item xs={12}>
          <Alert severity="error">{errorMessage}</Alert>
        </Grid>
      )}
      {loading === false && dashboard && dashboard.length === 0 && (
        <Grid item xs={12}>
          <Alert severity="info">{t("stands.alert")}</Alert>
        </Grid>
      )}
      <Box
        justifyContent="center"
        sx={{
          display: 'grid',
          gap: 1,
          gridAutoRows: '150px',
          gridTemplateColumns: 'repeat(auto-fill, minmax(10em, 220px))'
        }}
      >
        {dashboard &&
          dashboard.map(item => {
            return <DashboardItem key={item.step} item={item}></DashboardItem>;
          })}
      </Box>
    </Stack>
  );
}
