export const se = {
  translation: {
    // component
    login: {
      login: "Logga in",
      regUser: "Registrera användare",
      resetPwd: "Återställ lösenord",
      errMess1: "Fel e-post eller lösenord",
      errMess2: "Ett oväntat fel inträffade",
      noAccount: "Kontot {{account}} finns inte",
      emailPlaceholder: "E-post",
      pwdPlaceholder: "Lösenord",
    },
    scannerPage: {
      errMess: "Ett fel har inträffat. Stativ kunde inte hämtas",
      manSearch: "Manuell sökning",
      searchPlaceholder: "Stativ",
      searchBtn: "Sök",
      regStand: "Registrera Stativ",
      noStandMess:
        "Tyvärr hittade vi inget stativ med detta nummer.\nVänligen försök att scanna stativet\neller manuellt registrera det",
      reg: "Registrera",
    },
    main: {
      home: "Hem",
      users: "Användare",
      projects: "Projekt",
      suppliers: "Leverantörer",
      stands: "Stativ",
      logout: "Logga ut",
    },
    projects: {
      project: "Projekt",
      errMess: "Hämta projekt misslyckades",
      new: "Nytt projekt",
    },
    projectItem: {
      new: "Nytt projekt",
      name: "Namn",
      active: "Aktiv",
      save: "Spara",
      undo: "Ångra",
      delete: "Ta bort projekt",
      notActive: "Inaktiv",
    },
    stand: {
      unknown: "Okänt stativ",
      alertTitle: "Fel streckkod?",
      alert: "Har ni scannat rätt streckkod?\nNumret innehåller mer än 100 tecken.",
      error: "Ett fel har inträffat!",
      labelSupplier: "LEVERANTÖR",
      labelProject: "PROJEKT",
      labelCarNr: "BIL NR",
      labelOrder: "ORDER",
      newScan: "Ny scan",
      back: "Tillbaka",
    },
    standConfirm: {
      error: "Ett fel har inträffat!",
      updated: "Stativ uppdaterat",
      car: "BIL",
    },
    standCreate: {
      errorMess: "Ett fel har inträffat. Det gick inte att spara stativet",
      standNumber: "Fyll i det fullständiga stativnumret",
      alert: "Vänligen fyll i det fullständiga stativnummret från streckkoden, samt det korta (om det finns något)",
      labelStandNumber: "Stativnummer (fullständigt)",
      labelStandNumberShort: "Stativnummer (kort)",
      chooseSupplier: "Välj leverantör",
      supplier: "Leverantör",
      registerStand: "Registrera stativ",
      back: "Tillbaka",
    },
    standSelect: {
      alertTitle: "Flera stativ hittades",
      alert: "Vänligen välj ett för att gå vidare",
      stand: "Stativ",
      labelInactive: "Inaktiv",
      supplier: "Leverantör",
      project: "Projekt",
      choose: "Välj",
      register: "Registrera nytt stativ",
    },
    standHistory: {
      history: "Historik",
      noHistory: "Ingen historik tillgänglig",
      date: "Datum",
      project: "Projekt",
      process: "Process",
      car: "Bil",
      order: "Order",
    },
    standItem: {
      supplier: "Leverantör",
      project: "Projekt",
      car: "Bil",
      order: "Order",
      inactive: "inaktivt",
      delete: "Ta bort stativ",
      delayed: "Försenad datum",
    },
    stands: {
      getStandFail: "Hämta stativ misslyckades",
      stand: "Stativ",
      filterStand: "Filtrera stativ",
      supplier: "Leverantör",
      project: "Projekt",
      step: "Steg",
      active: "Aktiv",
      inactive: "inaktivt",
      order: "Order",
      transport: "Transport",
      filter: "Filtrera",
      clear: "Rensa",
      close: "Stäng",
      download: "Ladda ner stativ",
      alert: "Inga träffar",
      noFilterTitle: "Filtrera stativ",
      noFilterMessage: "Vänligen välj minst ett filter för att visa stativ",
      listView: "Visa lista",
      dashboardView: "Visa dashboard"
    },
    dashboardItem: {
      delayed: "Försenade stativ"
    },
    supplierItem: {
      newSupplier: "Ny leverantör",
      nameLabel: "Namn",
      save: "Spara",
      undo: "Ångra",
      removeSupplier: "Ta bort leverantör",
    },
    suppliers: {
      errMess: "Hämta leverantörer misslyckades",
      suppliers: "Leverantörer",
      newSupplier: "Ny leverantör",
    },
    forgotPassword: {
      errMess: "Ett fel har inträffat",
      resetPassword: "Återsäll lösenord",
      alertTitle_1: "Ett mail har skickats!",
      alert_1: "Vänligen följ instruktionerna i meddelandet",
      alertTitle_2: "Fyll i din e-post adress",
      alert_2:
        "En aktiveringslänk kommer sedan skickas till samma adress.\nNotera att e-post måste vara samma som du loggar in med.",
      labelEmail: "E-post",
      send: "Skicka",
      toLogin: "Till login",
    },
    resetPassword: {
      errMess_1:
        "Ett fel inträffade \n\nTyvärr går det inte att sätta lösenord med aktuell länk. Vänligen försök igen.",
      errMess_2: "Ett fel inträffade \n\nTyvärr går det inte att uppdatera det nya lösenordet. Vänligen försök igen.",
      errMess_3: "Ett fel inträffade",
      resetPassword: "Återsäll lösenord",
      labelPassword: "Nytt lösenord",
      labelRepeat: "Repetera nytt lösenord",
      toLogin: "Till login",
    },
    activateUser: {
      errMess_1: "Aktiveringslänken kan ej användas. Vänligen beställ en ny länk nedan.",
      errMess_2: "Skicka aktiverings länk misslyckades. Vänligen försök senare",
      actAccount: "Aktivera konto",
      alert_1: "Ditt konto är aktiverat!",
      alert_2: "En aktiveringslänk har skickats till din e-post",
      start: "START",
      toLogin: "Till login",
      alert_3: "Ett fel har inträffat",
      sendActLink: "Beställ aktiveringslänk",
      label: "E-post",
      send: "Beställ",
      alert_4: "Notera att en länk endast är giltig 1 gång och upp till 24 timmar.",
    },
    registerUser: {
      errMess: "Ett fel inträffade",
      regUser: "Registrera användare",
      labelName: "Namn",
      labelEmail: "E-post",
      labelPassword: "Lösenord",
      register: "Registrera",
      alert_1: "E-post måste tillhöra",
      toLogin: "Till login",
      alert_2: "En aktiveringslänk har skickats till din e-post",
      alertTitle_2: "Användare registrerad!",
      alertTitle_3: "Ett fel har inträffat!",
    },
    userItem: {
      newUser: "Ny användare",
      inactive: "Inaktiv",
      labelName: "Namn",
      labelEmail: "E-post",
      admin: "Administratör",
      active: "Aktiv",
      save: "Spara",
      undo: "Ångra",
      delete: "Ta bort användare",
      lastLogin: "Senast inloggad"
    },
    users: {
      errMess: "Hämta användare misslyckades",
      user: "Användare",
      newUser: "Ny användare",
    },
    userSettings: {
      accSettings: "Kontoinställningar",
      name: "NAMN",
      email: "E-POST",
      labelPassword: "Nytt lösenord",
      labelRepeat: "Repetera nytt lösenord",
      save: "Spara",
      alertTitle_1: "Lösenord sparat!",
      alertTitle_2: "Ett fel har inträffat",
    },
    deleteButton: {
      delete: "Ta bort",
      confirm: "Är du säker?",
      continue: "Fortsätt",
      undo: "Ångra",
    },
    scanner2: {
      errMess: "QR eller streckkod får ej innehålla en url (länk)",
      invalidContent: "Ogiltigt innehåll",
      start: "Starta scanner",
      stop: "Stoppa scanner",
      switch: "BYT KAMERA",
    },
  },
};
