import React, { useState, useEffect } from "react";
import service from "../../services/stands-service";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Chip, Grid, Stack, TextField, Alert, CircularProgress } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/system";
import moment from "moment/min/moment-with-locales";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";

export default function StandHistory(props) {
  const { t } = useTranslation();
  const { stand } = props;
  const [stands, setStands] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [expanded, setExpanded] = useState(false);
  const [emptyRows, setEmptyRows] = useState(10);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const tryGetHistory = async () => {
    setErrorMessage(null);
    if (!loading && stands === null) {
      console.log("tryGetHistory");
      setLoading(true);
      await service
        .getHistory(stand.number, stand.supplierId, moment().add(-24, "months").format("YYYY-MM-DD"))
        .then(res => {
          console.log("tryGetHistory", res);
          setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - res.length) : 0);
          setStands(res);
        })
        .catch(error => {
          console.log("failed to get history", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleAccordionChange = e => {
    setExpanded(!expanded);
    console.log("handleAccordionChange", expanded);
    tryGetHistory();
  };

  useEffect(() => {}, []);
  return (
    <Accordion key={stand.id} expanded={expanded} onChange={handleAccordionChange} sx={{ background: "#D3D3D3" }}>
      <AccordionSummary mb={0} pb={0} expandIcon={<ExpandMoreIcon />} aria-controls={stand.id} id={stand.id}>
        <Grid container>
          <Grid item xs={12}>
            <Typography mr={4}>{t("standHistory.history")}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails mb={0} pb={0} sx={{ maxHeight: "200px", overflowY: "auto" }}>
        <Grid container alignItems="center" justifyContent="left">
          {loading && <CircularProgress />}
          <Grid item xs={12} mt={0}>
            {stands && stands.length === 0 && <Typography>{t("standHistory.noHistory")}</Typography>}
            {stands && stands.length > 0 && (
              <TableContainer component={Paper} mt={0} p={0}>
                <Table sx={{ minWidth: 650 }} aria-label="" size="small" mt={0} pt={0}>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("standHistory.date")}</TableCell>
                      <TableCell>{t("standHistory.project")}</TableCell>
                      <TableCell>{t("standHistory.process")}</TableCell>
                      <TableCell>{t("standHistory.car")}</TableCell>
                      <TableCell>{t("standHistory.order")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stands.map(row => (
                      <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell component="th" scope="row" title={row.timestamp}>
                          <Moment format="D MMM YYYY">{row.timestamp}</Moment>
                        </TableCell>
                        <TableCell>{row.project}</TableCell>
                        <TableCell>{row.stepDescription}</TableCell>
                        <TableCell>{row.transportReference}</TableCell>
                        <TableCell>{row.orderReference}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
