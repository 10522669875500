import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../context/AuthContext";
import suppliersService from "../../services/suppliers-service";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import { Alert, CircularProgress, Container, Fab, Grid, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import SupplierItem from "./SupplierItem";
import { useTranslation } from "react-i18next";

export default function Suppliers() {
  const { t } = useTranslation();
  const { accountData } = useAuth();
  const [suppliers, setSuppliers] = useState();
  const [canAdd, setCanAdd] = useState(true);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState();

  const getSuppliers = async () => {
    setErrorMessage(null);
    await suppliersService.getSuppliers().then(res => {
      setSuppliers(res);
    });
  };

  const enableAdd = supplier => {
    console.log("enableAdd");
    console.log(suppliers);
    setCanAdd(true);

    let clone = suppliers.filter(p => !(p.id === null || p.id === undefined));
    if (supplier) {
      clone.unshift(supplier);
    }
    setSuppliers(clone);
  };

  const deleteSupplier = supplier => {
    console.log("deleteSuppliers");
    console.log(supplier);
    if (supplier) {
      let clone = suppliers.filter(p => p.id !== supplier.id);
      setSuppliers(clone);
    }
  };

  const addSupplier = async e => {
    const id = e.target.id;
    console.log("add supplier");
    setCanAdd(false);
    const clone = [...suppliers];
    clone.unshift({ name: "", active: true });
    setSuppliers(clone);
  };

  useEffect(() => {
    (async () => {
      try {
        await getSuppliers();
      } catch (error) {
        console.log(error);
        setErrorMessage(t("suppliers.errMess"));
      } finally {
        setLoading(false);
      }
    })();
  }, []);
  return (
    <Box
      pt={2}
      sx={{
        background: accountData.settings.bodyBackground,
        height: "calc(98vh - 65px)",
        overflow: "auto",
      }}
    >
      <Container maxWidth="md">
        <Grid container>
          <Grid item xs={10}>
            <Typography mb={2} variant="h6">
              {t("suppliers.suppliers")}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" justifyContent="flex-end" mr={2}>
              <Tooltip title={t("suppliers.newSupplier")}>
                <Fab size="small" color="primary" aria-label="add" onClick={addSupplier} disabled={!canAdd}>
                  <AddIcon />
                </Fab>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
        {loading && <CircularProgress />}
        {errorMessage && (
          <Grid item xs={12}>
            <Alert severity="error">{errorMessage}</Alert>
          </Grid>
        )}
        {suppliers &&
          suppliers.map(supplier => {
            return (
              <SupplierItem
                key={supplier.id}
                item={supplier}
                onAddItem={enableAdd}
                onDeleteItem={deleteSupplier}
              ></SupplierItem>
            );
          })}{" "}
      </Container>
    </Box>
  );
}
