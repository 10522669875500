const getActivateUserAction = (accountId, email) => {
  return {
    accountId: accountId,
    email: email,
    action: "ActivateUser",
    subject: "Aktivera konto",
    url: `${process.env.REACT_APP_WEB}/${accountId}/activateuser`,
    message: "Klicka på länken för att aktivera ditt konto, observera att denna länk bara är giltig en gång.\n\n",
  }
};

const getResetPasswordAction = (accountId, email) => {
  return {
    accountId: accountId,
    email: email,
    action: "ResetPassword",
    subject: "Återställ lösenord",
    url: `${process.env.REACT_APP_WEB}/${accountId}/resetpassword`,
    message: "Klicka på länken för att återställa ditt lösenord. Observera att denna länk endast kan användas en gång.\n\n",
  }
};

export default {
  getActivateUserAction,
  getResetPasswordAction
};
