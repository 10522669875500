import axios from "axios";

const getSuppliers = async () => {
  const response = await axios.get(`/suppliers`);
  return response.data;
};

const save = async data => {
  const response = await axios.post(`/suppliers`, data);
  return response.data;
};

const deleteSupplier = async id => {
  await axios.delete(`/suppliers/${id}`);
};

export default {
  getSuppliers,
  save,
  deleteSupplier
};
