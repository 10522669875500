import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  Autocomplete,
  Backdrop,
  CircularProgress,
  Stack,
  Alert,
  AlertTitle,
  Tooltip,
} from "@mui/material";

import standsService from "../../services/stands-service";
import projectsService from "../../services/projects-service";
import suppliersService from "../../services/suppliers-service";
import StandSelect from "./StandSelect";
import { useAuth } from "../../context/AuthContext";

import processService from "../../services/process-service";
import StringHelper from "../../services/string-helper";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import { useTranslation } from "react-i18next";
import moment from "moment/min/moment-with-locales";

export default function Stage() {
  const { t } = useTranslation();
  const { id, account } = useParams();
  const { accountData } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();
  const btnColor = "#242424";
  const [projects, setProjects] = useState();
  const [suppliers, setSuppliers] = useState();
  const [project, setProject] = useState();
  const [supplier, setSupplier] = useState();
  const [transportReference, setTransportReference] = useState();
  const [orderReference, setOrderReference] = useState();
  const [showSelectStand, setShowSelectStand] = useState(false);
  const [stand, setStand] = useState();
  const [processSteps, setProcessSteps] = useState();
  const [error, setError] = useState(false);
  const [updatingStand, setUpdatingStand] = useState(false);
  const [barcodeFormat, setBarcodeFormat] = useState();
  const [numberWarning, setNumberWarning] = useState();
  const [loading, setLoading] = useState(true);
  const [canUpdate, setCanUpdate] = useState(false);
  const [supplierInputValue, setSupplierInputValue] = useState("");

  const onStandSelected = (stand) => {
    console.log("onStandSelected");
    setShowSelectStand(false);
    setTransportReference(
      stand.transportReference ? stand.transportReference : ""
    );
    setOrderReference(stand.orderReference ? stand.orderReference : "");
    stand = tryClearStand(stand);
    navigate(`/${account}/${stand.id}`);
    if (stand.supplier) {
      setCanUpdate(true);
    }
    setStand([stand]);
  };

  const tryClearStand = (stand) => {
    if (!stand.active) {
      stand.project = null;
      stand.projectId = null;
      stand.step = null;
      stand.stepDescription = null;
      stand.transportReference = null;
      stand.orderReference = null;
    }
    return stand;
  };

  const getEmptyStand = (number) => {
    return {
      accountId: account,
      active: true,
      barcodeFormat: "",
      id: null,
      number: number,
      orderReference: "",
      project: "",
      projectId: "",
      step: "",
      supplier: "",
      supplierId: "",
      transportReference: "",
      visualNumber: "",
    };
  };

  const updateStand = async (p, s) => {
    setUpdatingStand(true);
    setError(false);
    const lastStep = processSteps.slice(-1)[0];
    console.log("updateStand", s, lastStep);

    s.step = p.step;
    s.active = s.step === lastStep.step ? false : true;

    s.stepDescription = p.description;
    if (project) {
      const selectedProject = projects.find((p) => {
        return p.name === project;
      });
      if (selectedProject) {
        s.projectId = selectedProject.id;
        s.project = selectedProject.name;
      }
    }
    if (supplier) {
      const selectedSupplier = suppliers.find((s) => {
        return s.name === supplier;
      });
      if (selectedSupplier) {
        s.supplierId = selectedSupplier.id;
        s.supplier = selectedSupplier.name;
      }
    }
    s.transportReference = transportReference;
    s.orderReference = orderReference;
    s.barcodeFormat = barcodeFormat;
    s.delayed = null;
    if (p.delayDays) {
      s.delayed = moment()
        .add(p.delayDays + 1, "days")
        .format("YYYY-MM-DD");
    }
    console.log("updateStand", s);
    standsService
      .addStand(s)
      .then((res) => navigate("confirm", { state: res }))
      .catch((error) => {
        console.log(error);
        setError(true);
      })
      .finally(() => setUpdatingStand(false));
  };

  const getProjects = async () => {
    projectsService
      .getProjects()
      .then((res) => {
        setProjects(res);
      })
      .catch((e) => {
        console.log("Failed to get projects", e);
        setError(true);
      });
  };

  const getSuppliers = async () => {
    suppliersService
      .getSuppliers()
      .then((res) => {
        setSuppliers(res);
      })
      .catch((e) => {
        console.log("Failed to get suppliers", e);
        setError(true);
      });
  };

  const getProcessSteps = async () => {
    processService
      .getProcessSteps()
      .then((res) => {
        console.log("steps", res);
        setProcessSteps(res);
      })
      .catch((e) => {
        console.log("Failed to get process", e);
        setError(true);
      });
  };

  const getStands = async () => {
    if (state && state.scanner) {
      const stands = await standsService.getStandsByNr(id);
      return stands;
    } else {
      return await standsService.getStandsByVisualNr(id);
    }
  };

  const trySetStands = (stands) => {
    if (stands.length === 0) {
      console.log("trySetStands.. new");
      setStand([getEmptyStand(id)]);
    }
    if (stands.length === 1) {
      console.log("trySetStands.. single match");
      const stand = stands[0];
      if (stand.active) {
        setTransportReference(
          stand.transportReference ? stand.transportReference : ""
        );
        setOrderReference(stand.orderReference ? stand.orderReference : "");
      }
      if (stand.supplier) {
        // setCanUpdate(true);
        setSupplier(stand.supplier);
      }
      const s = tryClearStand(stand);
      setStand([s]);
    }
    if (stands.length > 1) {
      console.log("trySetStands.. multiple");
      setStand(stands);
      setShowSelectStand(true);
    }
  };

  const getStandById = async (id) => {
    standsService
      .getStandById(id)
      .then((res) => {
        if (res && res.supplier) {
          setCanUpdate(true);
        }
        setStand([res]);
      })
      .catch((e) => {
        console.log("Failed to get stand by id", e);
        setError(true);
      });
  };

  const checkNumber = (number) => {
    if (number.length > 100) {
      setNumberWarning(true);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        checkNumber(id);
        if (state) {
          if (state.transportReference) {
            setTransportReference(state.transportReference);
          }
          if (state.orderReference) {
            setOrderReference(state.orderReference);
          }
          if (state.scanner) {
            setBarcodeFormat(state.barcodeFormat);
          }
        }

        if (StringHelper.isGuid(id)) {
          getStandById(id);
        } else {
          try {
            const stands = await getStands();
            console.log("getStands", stands);
            trySetStands(stands);
          } catch (error) {
            console.log("Failed to get stands", error);
            setError(true);
          }
        }

        await getProjects();
        await getSuppliers();
        await getProcessSteps();
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("useEffect failed", error);
        setError(true);
      }
    })();
  }, []);

  const handleCanUpdate = () => {
    if (supplier) {
      setCanUpdate(true);
    } else {
      setCanUpdate(false);
    }

    if (supplierInputValue !== supplier) {
      setCanUpdate(false);
    }

    /////////////////////////

    //   const match =
    //     suppliers &&
    //     suppliers.filter(
    //       (item) => item.name.toLowerCase() === supplierInputValue.toLowerCase()
    //     );

    //   if (match && match.length > 0) {
    //     setSupplier(match[0].name);
    //     setCanUpdate(true);
    //   } else {
    //     setCanUpdate(false);
    //   }
  };

  useEffect(() => {
    handleCanUpdate();
  }, [supplierInputValue]);

  return (
    <>
      <Box
        pt={2}
        sx={{
          background: accountData.settings.bodyBackground,
          height: "90vh",
          overflow: "auto",
        }}
      >
        {stand && (
          <>
            {loading && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={updatingStand}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Box sx={{ display: "flex" }}></Box>
            {showSelectStand ? (
              <StandSelect stands={stand} onSelected={onStandSelected} />
            ) : (
              <Container
                maxWidth="xs"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography
                    variant="Subtitle1"
                    component="h2"
                    sx={{ wordWrap: "break-word" }}
                  >
                    {stand[0].number}
                  </Typography>
                  {(stand[0].id === null ||
                    stand[0].id === undefined ||
                    stand[0].id === "") && (
                    <Tooltip title={t("stand.unknown")}>
                      <HelpCenterIcon />
                    </Tooltip>
                  )}
                </Stack>

                {numberWarning && (
                  <Alert variant="filled" severity="warning">
                    <AlertTitle>{t("stand.alertTitle")}</AlertTitle>
                    {t("stand.alert")}
                  </Alert>
                )}
                {error && (
                  <Alert variant="filled" severity="error">
                    <strong>{t("stand.error")}</strong>
                  </Alert>
                )}
                {suppliers && projects && (
                  <Container
                    mt={4}
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                  >
                    <Autocomplete
                      value={supplier && supplier}
                      id="supplier"
                      freeSolo
                      options={suppliers.map((option) => option.name)}
                      renderInput={(params) => (
                        <TextField
                          variant="filled"
                          {...params}
                          label={t("stand.labelSupplier")}
                        />
                      )}
                      inputValue={supplierInputValue}
                      onInputChange={(e, newInputValue) => {
                        setSupplierInputValue(newInputValue);
                      }}
                      onChange={(e, newValue) => {
                        setSupplier(newValue);
                      }}
                    />
                    <Autocomplete
                      value={stand[0].project}
                      id="project"
                      freeSolo
                      options={projects
                        .filter((option) => option.active)
                        .map((x) => x.name)}
                      renderInput={(params) => (
                        <TextField
                          variant="filled"
                          {...params}
                          label={t("stand.labelProject")}
                        />
                      )}
                      // onSelect={(e) => setProject(e.target.value)}
                      onChange={(e, newValue) => {
                        setProject(newValue);
                      }}
                    />
                    <TextField
                      autoComplete="off"
                      value={transportReference}
                      id="trpt ref"
                      label={t("stand.labelCarNr")}
                      variant="filled"
                      onChange={(e) => setTransportReference(e.target.value)}
                    />
                    <TextField
                      autoComplete="off"
                      value={orderReference}
                      id="order ref"
                      label={t("stand.labelOrder")}
                      variant="filled"
                      onChange={(e) => setOrderReference(e.target.value)}
                    />
                  </Container>
                )}
                {processSteps &&
                  processSteps.map((p) => {
                    return (
                      <Container
                        key={p.step}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}
                      >
                        <Button
                          sx={{
                            background: btnColor,
                          }}
                          variant="contained"
                          onClick={() => updateStand(p, stand[0])}
                          disabled={!canUpdate}
                        >
                          {p.description}
                        </Button>
                      </Container>
                    );
                  })}
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="stretch"
                  spacing={1}
                >
                  <Button
                    variant="outlined"
                    sx={{ borderColor: btnColor, color: btnColor }}
                    onClick={() => navigate(`/${account}`)}
                  >
                    {t("stand.newScan")}
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ borderColor: btnColor, color: btnColor }}
                    onClick={() => navigate(-1)}
                  >
                    {t("stand.back")}
                  </Button>
                </Stack>
              </Container>
            )}
          </>
        )}
      </Box>
    </>
  );
}
