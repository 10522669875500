import React, { useState, useEffect } from "react";
import { btnColor } from "../../Accounts-styles";
import { Navigate, useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import { Password } from "@mui/icons-material";
import { Box, Container, Button, TextField, Stack, Icon, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useAuth } from "../../context/AuthContext";
import usersService from "../../services/users-service";
import userActionService from "../../services/userAction-service";
import accountsService from "../../services/accounts-service";
import authService from "../../services/auth-service";
import Header from "../../components/Header";
import createManifest from "../../services/pwa-manifest-helper";
import { useTranslation } from "react-i18next";

export default function ResetPassword() {
  const { t } = useTranslation();
  const { account } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  console.log(`ResetPassword. ${account}, ${id}`);
  const { accountData, setAccountData } = useAuth();
  const navigate = useNavigate();
  const [payload, setPayload] = useState({ password: "" });
  const [passwordCheck, setPasswordCheck] = useState();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRepeatNewPassword, setShowRepeatNewPassword] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState();

  const getAccount = async () => {
    return await accountsService.getAccount(account);
  };

  const getUserActionById = () => {
    userActionService
      .getUserActionById(id)
      .then(res => {
        authService.setToken(res.token);
      })
      .catch(error => {
        console.log("Failed to get user action", error);
        setErrorMessage(t("resetPassword.errMess_1"));
      });
  };
  const savePassword = async () => {
    if (passwordCheck === payload.password) {
      console.log("savePassword");
      await usersService
        .changePassword(payload)
        .then(data => {
          console.log("savePassword", data);
          navigate(`/${account}`);
        })
        .catch(error => {
          console.log("Failed to get user action. ", error);
          setErrorMessage(t("resetPassword.errMess_2"));
        });
    }
  };
  useEffect(() => {
    (async () => {
      try {
        if (account === null || account === undefined) {
          console.log("Account is missing?!");
        }
        getUserActionById();

        if (accountData === null || accountData === undefined) {
          console.log("accountData not found.. getting");
          const a = await getAccount();
          console.log("account found", a);
          setAccountData(a);
          createManifest(a);
        }
      } catch (error) {
        console.log(error);
        setErrorMessage(t("resetPassword.errMess_3"));
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <Header />

      {accountData && (
        <Box pt={2} sx={{ background: accountData.settings.bodyBackground, height: "90vh" }}>
          <Container
            maxWidth="xs"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Container sx={{ alignSelf: "center", marginTop: "40px" }}>
              <Typography mb={2} variant="h6">
                {t("resetPassword.reset")}
              </Typography>
              <Stack spacing={2}>
                <TextField
                  autoComplete="new password"
                  onChange={e => setPayload({ ...payload, password: e.target.value })}
                  fullWidth={true}
                  id="outlined-basic"
                  label={t("resetPassword.labelPassword")}
                  variant="outlined"
                  type={showNewPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <inputAdornmentClasses position="end">
                        <Icon
                          sx={{ cursor: "pointer" }}
                          aria-label="toggle password visibility"
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          onMouseDown={() => setShowNewPassword(!showNewPassword)}
                        >
                          {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </Icon>
                      </inputAdornmentClasses>
                    ),
                  }}
                />
                <TextField
                  label={t("resetPassword.labelRepeat")}
                  fullWidth={true}
                  variant="outlined"
                  type={showRepeatNewPassword ? "text" : "password"}
                  onChange={e => setPasswordCheck(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <inputAdornmentClasses position="end">
                        <Icon
                          sx={{ cursor: "pointer" }}
                          aria-label="toggle password visibility"
                          onClick={() => setShowRepeatNewPassword(!showRepeatNewPassword)}
                          onMouseDown={() => setShowRepeatNewPassword(!showRepeatNewPassword)}
                        >
                          {showRepeatNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </Icon>
                      </inputAdornmentClasses>
                    ),
                  }}
                />
              </Stack>
              <Stack spacing={2} sx={{ textAlign: "center" }}>
                <Button
                  fullWidth={true}
                  variant="contained"
                  sx={{ marginTop: "20px", background: btnColor }}
                  onClick={savePassword}
                >
                  {t("resetPassword.resetPassword")}
                </Button>
                <Button
                  fullWidth={true}
                  variant="outlined"
                  sx={{ borderColor: btnColor, color: btnColor }}
                  onClick={() => navigate(`/${account}/Login`)}
                >
                  {t("resetPassword.toLogin")}
                </Button>
              </Stack>
            </Container>
          </Container>
        </Box>
      )}
    </>
  );
}
