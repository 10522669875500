import zapoteLogo from "./images/zapote_white.png";
import fasadglasLogo from "./images/fasadglas-logo-green.png";

export const btnColor = "#242424";

export const styles = {
  zapote: {
    headerColor: "darkorange",
    logo: zapoteLogo,
    bodyBackground: "orange",
  },
  fasadglas: {
    headerColor: "#2e8d2c",
    logo: fasadglasLogo,
    bodyBackground: "#73c571",
  },
};
