import React, { useEffect, useState } from "react";
import { Box, Container, Alert, AlertTitle, Stack, Chip, Grid } from "@mui/material";
import { useParams, useNavigate } from "react-router";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { btnColor } from "../../Accounts-styles";
import { useTranslation } from "react-i18next";

export default function StageSelect({ stands, onSelected }) {
  const { t } = useTranslation();
  const { account } = useParams();
  const navigate = useNavigate();

  const handleSelectedStand = stand => {
    console.log("selected stand", stand);
    onSelected(stand);
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Box mb={2}>
        <Alert severity="info" sx={{ minWidth: 275 }}>
          <AlertTitle>{t("standSelect.alertTitle")}</AlertTitle>
          {t("standSelect.alert")}
        </Alert>
      </Box>
      <Container>
        <Stack spacing={1} mb={2}>
          {stands &&
            stands.map(s => {
              return (
                <Card key={s.id} sx={{ minWidth: 275 }} mb={1}>
                  <CardContent>
                    <Stack spacing={1}>
                      <Stack direction="row" alignItems="stretch">
                        <Grid container>
                          <Grid item xs={12} md={8}>
                            <Typography variant="h6" component="div">
                              {t("standSelect.stand")}: {s.number}
                            </Typography>
                          </Grid>
                          {s.active === false && (
                            <Grid item xs={12} md={4} justifyContent="flex-end">
                              <Chip
                                label={t("standSelect.labelInactive")}
                                sx={{ background: "gray", color: "whitesmoke" }}
                                size="small"
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Stack>

                      {s.supplier && (
                        <Typography mt={0.5} variant="body2">
                          {t("standSelect.supplier")}: {s.supplier}
                        </Typography>
                      )}
                      {s.project && s.project !== "" && (
                        <Typography mt={1} variant="body2">
                          {t("standSelect.project")}: {s.project}
                        </Typography>
                      )}

                      <Button
                        mt={3}
                        id={s.id}
                        size="small"
                        sx={{
                          background: btnColor,
                        }}
                        variant="contained"
                        onClick={() => handleSelectedStand(s)}
                      >
                        {t("standSelect.choose")}
                      </Button>
                    </Stack>
                  </CardContent>
                </Card>
              );
            })}
          <Button
            fullWidth={true}
            variant="outlined"
            sx={{ borderColor: btnColor, color: btnColor }}
            onClick={() => navigate(`/${account}/0/create`)}
          >
            {t("standSelect.register")}
          </Button>
        </Stack>
      </Container>
    </Container>
  );
}
