export const en = {
  translation: {
    // component
    login: {
      login: "Log in",
      regUser: "Register",
      resetPwd: "Reset password",
      errMess1: "Wrong email or password",
      errMess2: "An unknown error occured",
      noAccount: "Account {{account}} does not exist",
      emailPlaceholder: "Email",
      pwdPlaceholder: "Password",
    },
    scannerPage: {
      errMess: "An error occured. Unable to get Stand",
      manSearch: "Manual search",
      searchPlaceholder: "Stand",
      searchBtn: "Search",
      regStand: "Register stand",
      noStandMess: "We couldnt find a stand with this number.\nTry to scan the stand\nor manually register it",
      reg: "Register",
    },
    main: {
      home: "Home",
      users: "Users",
      projects: "Projects",
      suppliers: "Suppliers",
      stands: "Stands",
      logout: "Log out",
    },
    projects: {
      project: "Projects",
      errMess: "Failed to get project",
      new: "New project",
    },
    projectItem: {
      new: "New project",
      name: "Name",
      active: "Active",
      save: "Save",
      undo: "Undo",
      delete: "Delete",
      notActive: "Not active",
    },
    stand: {
      unknown: "Unknown stand",
      alertTitle: "Barcode error?",
      alert: "Did you scan the right barcode?\nThe number contains more than 100 characters.",
      error: "An error has occured!",
      labelSupplier: "SUPPLIER",
      labelProject: "PROJECT",
      labelCarNr: "CAR NR",
      labelOrder: "ORDER",
      newScan: "New scan",
      back: "Back",
    },
    standConfirm: {
      error: "An error has occured!",
      updated: "Stand uppdated",
      car: "CAR",
    },
    standCreate: {
      errorMess: "An error has occured. Unable to save stand",
      standNumber: "Please enter the complete stand number",
      alert: "Please enter the complete stand number from the barcode, and the short number (if there is any)",
      labelStandNumber: "Stand number (complete)",
      labelStandNumberShort: "Stand number (short)",
      chooseSupplier: "Choose supplier",
      supplier: "Supplier",
      registerStand: "Register stand",
      back: "Back",
    },
    standSelect: {
      alertTitle: "More than one stand was found",
      alert: "Please choose one to proceed",
      stand: "Stand",
      labelInactive: "Inactive",
      supplier: "Supplier",
      project: "Project",
      choose: "Choose",
      register: "Register new stand",
    },
    standHistory: {
      history: "History",
      noHistory: "No history available",
      date: "Date",
      project: "Project",
      process: "Process",
      car: "Car",
      order: "Order",
    },
    standItem: {
      supplier: "Supplier",
      project: "Project",
      car: "Car",
      order: "Order",
      inactive: "not active",
      delete: "Delete stand",
      delayed: "Delay date",
    },
    stands: {
      getStandFail: "Failed to get stand",
      stand: "Stands",
      filterStand: "Filter stand",
      supplier: "Supplier",
      project: "Project",
      step: "Step",
      active: "Active",
      inactive: "not active",
      order: "Order",
      transport: "Transport",
      filter: "Filter",
      clear: "Clear",
      close: "Close",
      download: "Download stand",
      alert: "No result",
      noFilterTitle: "Filter stands",
      noFilterMessage: "Please select at least one filter to display any stands",
      listView: "Show list",
      dashboardView: "Show dashboard"
    },
    dashboardItem: {
      delayed: "Stands delayed"
    },
    supplierItem: {
      newSupplier: "New supplier",
      nameLabel: "Name",
      save: "Save",
      undo: "Undo",
      removeSupplier: "Remove supplier",
    },
    suppliers: {
      errMess: "Failed to get suppliers",
      suppliers: "Suppliers",
      newSupplier: "New supplier",
    },
    forgotPassword: {
      errMess: "An error has occured",
      resetPassword: "Reset password",
      alertTitle_1: "An email has been sent!",
      alert_1: "Please follow the instructions in the email",
      alertTitle_2: "Enter your email",
      alert_2: "An activation link will be sent to your email.\nPlease use the same email you use for log in.",
      labelEmail: "Email",
      send: "Send",
      toLogin: "To login",
    },
    resetPassword: {
      errMess_1: "An error has occured \n\nUnable to update password with this link. Please try again.",
      errMess_2: "An error has occured \n\nUnable to update new password. Please try again.",
      errMess_3: "An error has occured",
      resetPassword: "Reset password",
      labelPassword: "New password",
      labelRepeat: "Repeat new password",
      toLogin: "To login",
    },
    activateUser: {
      errMess_1: "Aktivationlink is not valid, please order a new link below.",
      errMess_2: "Failed to send activation link, please try again later",
      actAccount: "Activate account",
      alert_1: "Your account has been activated!",
      alert_2: "An activation link has been sent to your email",
      start: "START",
      toLogin: "To login",
      alert_3: "An error has occured",
      sendActLink: "Send activation link",
      label: "Email",
      send: "Send",
      alert_4: "Please note that the link is only valid 1 time and expires after 24 hours.",
    },
    registerUser: {
      errMess: "An error has occured",
      regUser: "Register user",
      labelName: "Name",
      labelEmail: "Email",
      labelPassword: "Password",
      register: "Register",
      alert_1: "Email has to belong to",
      toLogin: "To login",
      alert_2: "An activation link has been sent to your email",
      alertTitle_2: "User registered!",
    },
    userItem: {
      newUser: "New user",
      inactive: "Not active",
      labelName: "Name",
      labelEmail: "Email",
      admin: "Administrator",
      active: "Active",
      save: "Save",
      undo: "Undo",
      delete: "Remove user",
      lastLogin: "Last login"
    },
    users: {
      errMess: "Failed to get user",
      user: "Users",
      newUser: "New user",
    },
    userSettings: {
      accSettings: "Account settings",
      name: "NAME",
      email: "EMAIL",
      labelPassword: "New password",
      labelRepeat: "Repeat new password",
      save: "Save",
      alertTitle_1: "Password saved!",
      alertTitle_2: "An error has occured",
    },
    deleteButton: {
      delete: "Delete",
      confirm: "Are you sure?",
      continue: "Continue",
      undo: "Close",
    },
    scanner2: {
      errMess: "QR/ barcode cannot contain a url (link)",
      invalidContent: "Invalid content",
      start: "Start scanner",
      stop: "Stop scanner",
      switch: "SWITCH CAMERA",
    },
  },
};
