import React, { useState, useEffect } from "react";
import { Password } from "@mui/icons-material";
import {
  Box,
  Container,
  Button,
  TextField,
  Stack,
  Icon,
  Typography,
  Alert,
  AlertTitle,
  CircularProgress,
} from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import { btnColor } from "../../Accounts-styles";
import { useNavigate, useParams } from "react-router";
import userActionService from "../../services/userAction-service";
import accountService from "../../services/accounts-service";
import userActionHelper from "../../services/user-action-helper";
import Header from "../../components/Header";
import createManifest from "../../services/pwa-manifest-helper";
import { useTranslation } from "react-i18next";

export default function ForgotPassword() {
  const { t } = useTranslation();
  const { account } = useParams();
  const navigate = useNavigate();
  const { accountData, setAccountData } = useAuth();
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState();
  const [success, setSuccess] = useState(false);
  const [btnSpinner, setBtnSpinner] = useState(false);
  const [payload, setPayload] = useState();

  const postUserAction = async () => {
    setBtnSpinner(true);
    setErrorMessage(null);
    userActionService
      .createUserAction(payload, (payload.email = email))
      .then(res => {
        setSuccess(true);
        setBtnSpinner(false);
      })
      .catch(error => {
        setErrorMessage("Ett fel har inträffat");
        setBtnSpinner(false);
      });
  };

  const getAccount = async () => {
    console.log("getAccount", account);
    return await accountService.getAccount(account);
  };

  useEffect(() => {
    (async () => {
      try {
        const userAction = userActionHelper.getResetPasswordAction(account, "");
        setPayload(userAction);
        if (accountData === null || accountData === undefined) {
          console.log("accountData not found.. getting");
          const a = await getAccount();
          console.log("account found", a);
          setAccountData(a);
          createManifest(a);
        }
      } catch (error) {
        console.log(error);
        setErrorMessage(t("forgotPassword.errMess"));
      } finally {
        setLoading(false);
      }
    })();
  }, [email]);

  return (
    <>
      <Header />

      {accountData && (
        <Box sx={{ background: accountData.settings.bodyBackground, height: "90vh" }}>
          <Container
            maxWidth="xs"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {loading && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress sx={{ color: btnColor }} />
              </Box>
            )}
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            <Container sx={{ alignSelf: "center", marginTop: "40px" }}>
              <Typography mb={2} variant="h6">
                {t("forgotPassword.resetPassword")}
              </Typography>
              {success && (
                <>
                  <Box mb={4}>
                    <Alert severity="success">
                      <AlertTitle>{t("forgotPassword.alertTitle_1")}</AlertTitle>
                      {t("forgotPassword.alert_1")}
                    </Alert>
                  </Box>
                </>
              )}
              {!success && (
                <>
                  <Box mb={4}>
                    <Alert severity="info">
                      <AlertTitle>{t("forgotPassword.alertTitle_2")}</AlertTitle>
                      {t("forgotPassword.alert_2")}
                    </Alert>
                  </Box>

                  <Stack spacing={2}>
                    <TextField
                      autoComplete="new email"
                      onChange={e => setEmail(e.target.value)}
                      id="filled-basic"
                      label={t("forgotPassword.labelEmail")}
                      variant="filled"
                    />
                  </Stack>
                  <Stack spacing={2} sx={{ textAlign: "center" }}>
                    <Button
                      variant="contained"
                      sx={{ marginTop: "20px", background: btnColor }}
                      onClick={postUserAction}
                    >
                      {t("forgotPassword.send")}&nbsp;
                      {btnSpinner && <CircularProgress size={17} sx={{ color: "white" }} />}
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{ borderColor: btnColor, color: btnColor }}
                      onClick={() => navigate(`/${account}/Login`)}
                    >
                      {t("forgotPassword.toLogin")}
                    </Button>
                  </Stack>
                </>
              )}
            </Container>
          </Container>
        </Box>
      )}
    </>
  );
}
