import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../context/AuthContext";
import service from "../../services/stands-service";
import suppliersService from "../../services/suppliers-service";
import projectsService from "../../services/projects-service";
import processService from "../../services/process-service";
import authHeader from "../../services/auth-header";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import FilterListIcon from "@mui/icons-material/FilterList";
import getQueryParams from "../../services/query-params-helper";
import {
  Alert,
  AlertTitle,
  Badge,
  Button,
  CircularProgress,
  Container,
  Divider,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Tooltip,
  Popover,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import StandItem from "./StandItem";
import moment from "moment/min/moment-with-locales";
import { useTranslation } from "react-i18next";

export default function List(props) {
  const { t } = useTranslation();
  const { filter } = props;
  const { accountData } = useAuth();
  const [stands, setStands] = useState();
  const [suppliers, setSuppliers] = useState();
  const [projects, setProjects] = useState();
  const [steps, setSteps] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const getStands = async () => {
    setErrorMessage(null);
    const filterExists = getQueryParams(filter) ? true : false;
    setFilterApplied(filterExists);
    if (!filterExists) {
      setStands(null);
      return;
    }
    setLoading(true);
    await service
      .getStands(filter)
      .then(res => {
        console.log("getStands", res);
        setStands(res);
      })
      .finally(() => {
        setRefresh(false);
        setLoading(false);
      });
  };

  const deleteStand = item => {
    console.log("deleteStand", item);
    if (item) {
      let newList = stands.filter(s => s.id !== item.id);
      setStands(newList);
    }
  };
  
  useEffect(() => {
    (async () => {
      try {
        await getStands();
      } catch (error) {
        console.log(error);
        setErrorMessage(t("stands.getStandFail"));
      } finally {
        setLoading(false);
      }
    })();
  }, [props.reload]);
  return (
    <Stack spacing={0}>
      {loading && <CircularProgress />}
      {errorMessage && (
        <Grid item xs={12}>
          <Alert severity="error">{errorMessage}</Alert>
        </Grid>
      )}
      {loading === false && stands && stands.length === 0 && (
        <Grid item xs={12}>
          <Alert severity="info">{t("stands.alert")}</Alert>
        </Grid>
      )}
      {!filterApplied && (
        <Grid item xs={12}>
          <Alert severity="info">
            <AlertTitle>{t("stands.noFilterTitle")}</AlertTitle>
            {t("stands.noFilterMessage")}
          </Alert>
        </Grid>

      )}
      {stands &&
        stands.length > 0 &&
        stands.map(stand => {
          return <StandItem key={stand.id} item={stand} onDeleteItem={deleteStand}></StandItem>;
        })}{" "}
    </Stack>

  );
}
