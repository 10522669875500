import React, { useState, useEffect } from "react";
import { Container, Button, Stack, Alert, Backdrop, CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { btnColor } from "../../Accounts-styles";
import { useParams } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";
import standsService from "../../services/stands-service";
import { useAuth } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";

export default function StandConfirm() {
  const { t } = useTranslation();
  const { account } = useParams();
  const { accountData } = useAuth();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {})();
  }, []);

  return (
    <Box sx={{ background: accountData.settings.bodyBackground, height: "calc(100vh - 65px)" }}>
      <Backdrop sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Stack
          mt={4}
          spacing={2}
          sx={{ background: "white", padding: "30px", borderRadius: "10px", minWidth: "200px" }}
        >
          {error ? (
            <Alert variant="filled" severity="error">
              <strong>{t("standConfirm.error")}</strong>
            </Alert>
          ) : (
            <Alert severity="success">
              <strong>{t("standConfirm.updated")}</strong>
            </Alert>
          )}
          <Stack spacing={2} sx={{ textAlign: "center" }}>
            <strong>{state.number}</strong>
            {state.step && <strong>{state.stepDescription}</strong>}
            {state.supplier && <strong>{state.supplier}</strong>}
            {state.project && <strong>{state.project}</strong>}
            <Stack spacing={0} sx={{ textAlign: "center" }}>
              {state.transportReference && (
                <Typography>
                  {t("standConfirm.car")}:<strong>{state.transportReference}</strong>
                </Typography>
              )}
              {state.orderReference && (
                <Typography>
                  ORDER: <strong> {state.orderReference}</strong>
                </Typography>
              )}
            </Stack>
          </Stack>
          <Button
            fullwidth="true"
            variant="contained"
            sx={{ background: btnColor }}
            onClick={() => navigate(`/${account}`)}
          >
            Scanner
          </Button>
        </Stack>
      </Container>
    </Box>
  );
}
