import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../context/AuthContext";
import usersService from "../../services/users-service";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import Moment from "react-moment";
import { Chip, Checkbox, Button, Grid, Stack, Switch, TextField, Alert, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import DeleteButton from "../../components/DeleteButton";
import { useTranslation } from "react-i18next";

export default function UserItem(props) {
  const { t } = useTranslation();
  const { item, onAddItem, onDeleteItem } = props;
  console.log('userItem', item);
  const { accountData } = useAuth();
  const [activeChecked, setActiveChecked] = useState(item.active);
  const [user, setUser] = useState(item);
  const [errorMessage, setErrorMessage] = useState();
  const [expanded, setExpanded] = useState(false);
  const [newItem, setNewItem] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const nameInput = useRef(null);

  const setNameFocus = () => {
    if (nameInput.current) {
      nameInput.current.focus();
    }
  };

  const handleAccordionChange = e => {
    console.log(`handleAccordionChange '${expanded}'`);
    setExpanded(!expanded);
  };

  const setFormValid = () => {
    setCanSave(user.name ? true : false);
  };

  const handleChange = e => {
    setErrorMessage(null);
    setFormValid();
    const name = e.target.name;
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    console.log(`name:${name}, value:${value}`);
    setUser({ ...user, [name]: value });
  };

  const handleActiveChanged = e => {
    console.log("handleActiveChanged");
    console.log(e);
    const value = e.target.checked;
    console.log(`name: value: ${value}`);
    setUser({ ...user, active: value });
  };

  const saveUser = async () => {
    setErrorMessage(null);
    console.log("save user");
    console.log(user);
    try {
      if (newItem) {
        user.accountId = accountData.id;
        const newUser = await usersService.createUser(user);
        user.id = newUser.id;
        console.log("user created!");
        console.log(newUser);
      }

      await usersService.updateUser(user);
      if (user.admin === true) {
        console.log("add role");
        await usersService.addRoleToUser(user.id, "Admin");
      } else {
        console.log("remove role");
        await usersService.removeRole(user.id, "Admin");
      }
      if (newItem && onAddItem) onAddItem(user);
      setExpanded(false);
    } catch (e) {
      setErrorMessage(e.message ? e.message : e);
    }
  };

  const deleteUser = async () => {
    console.log("delete user");
    setErrorMessage(null);
    try {
      await usersService.removeRole(user.id, "User");
      await usersService.removeRole(user.id, "Admin");

      if (onDeleteItem) onDeleteItem(user);
    } catch (e) {
      setErrorMessage(e.message ? e.message : e);
    }
  };

  const cancelSave = () => {
    if (newItem && onAddItem) onAddItem();
    setExpanded(false);
  };

  useEffect(() => {
    if (user.id === null || user.id === undefined) {
      setExpanded(true);
      setNewItem(true);
    }
    setFormValid();
    setNameFocus();
  }, []);
  return (
    <Accordion key={user.id} expanded={expanded} onChange={handleAccordionChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={user.id} id={user.id}>
        <Grid container>
          <Grid item xs={8}>
            {!newItem && user.name && (
              <Typography mr={4}>
                <strong>{user.name}</strong>
              </Typography>
            )}
            {newItem && (
              <Typography mr={4} color="primary.main">
                <strong>{t("userItem.newUser")}</strong>
              </Typography>
            )}
          </Grid>
          {!user.active && (
            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end" mr={1}>
                {!user.active && (
                  <Chip label={t("userItem.inactive")} sx={{ background: "gray", color: "whitesmoke" }} size="small" />
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      </AccordionSummary>

      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                ref={nameInput}
                size="small"
                id="outlined-basic"
                label={t("userItem.labelName")}
                name="name"
                defaultValue={user.name}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                size="small"
                id="outlined-basic"
                label={t("userItem.labelEmail")}
                name="email"
                defaultValue={user.email}
                onChange={handleChange}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} mt={0}>
            <Stack mt={0} spacing={0}>
              {item.lastLogin &&
                <Box component="span">
                  <Typography component="span" className="detailLabel">
                    {t("userItem.lastLogin")}:
                  </Typography>
                  <Moment format="D MMM YYYY HH:MM">{item.lastLogin}</Moment>
                </Box>
              }
            </Stack>
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack direction="row" spacing={4}>
              <div>
                <span className="detailLabel">{t("userItem.admin")}</span>
                <Checkbox name="admin" checked={user.admin} onChange={handleChange} />
              </div>
              <div>
                <span className="detailLabel">{t("userItem.active")}</span>
                <Switch defaultChecked={activeChecked} onChange={handleActiveChanged} />
              </div>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack direction="row" spacing={2}>
              <Button onClick={saveUser} size="small" variant="contained" disabled={!canSave}>
                {t("userItem.save")}
              </Button>
              {newItem && (
                <Button onClick={cancelSave} size="small" variant="contained" sx={{ background: "#6c757d" }}>
                  {t("userItem.undo")}
                </Button>
              )}
              {!newItem && (
                <DeleteButton
                  itemId={user.id}
                  text={t("userItem.delete")}
                  onClick={deleteUser}
                  color="error"
                ></DeleteButton>
              )}
            </Stack>
          </Grid>
          {errorMessage && (
            <Grid item xs={12}>
              <Alert severity="error">{errorMessage}</Alert>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
