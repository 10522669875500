import axios from "axios";

const getAccount = async id => {
  const response = await axios.get(`/accounts/${id}`);
  return response.data;
};

export default {
  getAccount,
};
