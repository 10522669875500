import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../context/AuthContext";
import projectsService from "../../services/projects-service";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import { Alert, CircularProgress, Container, Fab, Grid, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import ProjectItem from "./ProjectItem";
import { useTranslation } from "react-i18next";

export default function Projects() {
  const { t } = useTranslation();
  const { accountData } = useAuth();
  const [projects, setProjects] = useState();
  const [active, setActive] = useState();
  const [canAdd, setCanAdd] = useState(true);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState();

  const getProjects = async () => {
    setErrorMessage(null);
    await projectsService.getProjects().then(res => {
      setProjects(res);
    });
  };

  const enableAddProject = project => {
    console.log("enableAddProject");
    console.log(project);
    setCanAdd(true);

    let projectsWithoutId = projects.filter(p => !(p.id === null || p.id === undefined));
    if (project) {
      projectsWithoutId.unshift(project);
    }
    setProjects(projectsWithoutId);
  };

  const deleteProject = project => {
    console.log("deleteProject");
    console.log(project);
    if (project) {
      let projectsWithoutId = projects.filter(p => p.id !== project.id);
      setProjects(projectsWithoutId);
    }
  };

  const addProject = async e => {
    const id = e.target.id;
    console.log("add project");
    setCanAdd(false);
    const cloneProjects = [...projects];
    cloneProjects.unshift({ name: "", active: true });
    setProjects(cloneProjects);
  };

  useEffect(() => {
    (async () => {
      try {
        await getProjects();
      } catch (error) {
        console.log(error);
        setErrorMessage(t("projects.errMess"));
      } finally {
        setLoading(false);
      }
    })();
  }, []);
  console.log(active);

  return (
    <Box
      pt={2}
      sx={{
        background: accountData.settings.bodyBackground,
        height: "calc(98vh - 65px)",
        overflow: "auto",
      }}
    >
      <Container maxWidth="md">
        <Grid container>
          <Grid item xs={10}>
            <Typography mb={2} variant="h6">
              {t("projects.project")}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" justifyContent="flex-end" mr={2}>
              <Tooltip title={t("projects.new")}>
                <Fab size="small" color="primary" aria-label="add" onClick={addProject} disabled={!canAdd}>
                  <AddIcon />
                </Fab>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
        {loading && <CircularProgress />}
        {errorMessage && (
          <Grid item xs={12}>
            <Alert severity="error">{errorMessage}</Alert>
          </Grid>
        )}
        {projects &&
          projects.map(project => {
            return (
              <ProjectItem
                key={project.id}
                item={project}
                onAddItem={enableAddProject}
                onDeleteItem={deleteProject}
              ></ProjectItem>
            );
          })}{" "}
      </Container>
    </Box>
  );
}
