import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router";
import { Html5Qrcode } from "html5-qrcode";
import { Alert, AlertTitle, Button, Container, Stack, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import { btnColor } from "../Accounts-styles";
import "./styles/scanner2.scss";
import { useTranslation } from "react-i18next";

export default function Scanner2() {
  const { t } = useTranslation();
  const { account } = useParams();
  const [camera, setCamera] = useState("environment");
  const [scannerBusy, setScannerBusy] = useState(false);
  const [scannerStarted, setScannerStarted] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const qrcodeRegionId = "html5qr-code-full-region";
  const navigate = useNavigate();
  const scannerRef = useRef();
  const [toggleRotate, setToggleRotate] = useState("rotate(0deg)");
  const config = {
    fps: 20, // Optional, frame per seconds for qr code scanning
    qrbox: { width: 240, height: 240 }, // Optional, if you want bounded box UI
  };

  const toggleCamera = async () => {
    setErrorMessage(null);
    try {
      const ignored = await stopScan();
      console.log(`stopped scan. Ignored = ${ignored}`);
    } catch (error) {
      console.log("Stop scan failed", error);
    }
    const mode = camera === "environment" ? "user" : "environment";
    setCamera(mode);
    startScan(mode);
  };

  const toggleStart = async () => {
    setErrorMessage(null);
    try {
      if (scannerStarted) {
        await stopScan();
      } else {
        startScan(camera);
      }
    } catch (error) {
      console.log("Stop scan failed", error);
    }
  };

  const textValid = decodedText => {
    try {
      console.log("textValid:", typeof decodedText);
      if (typeof decodedText === "string") {
        return !decodedText.includes("//");
      }
      return true;
    } catch (error) {
      console.log("textValid failed.", error);
      return true;
    }
  };

  const startScan = async mode => {
    console.log(`startScan()`);
    setErrorMessage(null);
    if (scannerRef.current) {
      if (mode === null || mode === undefined) mode = camera;
      setScannerStarted(true);
      setScannerBusy(true);
      try {
        scannerRef.current
          .start(
            { facingMode: mode },
            config,
            (decodedText, decodedResult) => {
              console.log(`SCAN SUCCESS '${decodedText}'`, decodedResult);
              let format = null;
              try {
                format = decodedResult.result.format.formatName;
              } catch (error) {
                console.log(`SCAN complete. failed to get scan format`, error);
              }
              if (textValid(decodedText)) {
                navigate(`/${account}/${decodedText}`, { state: { scanner: true, barcodeFormat: format } });
              } else {
                setErrorMessage(t("scanner2.errMess"));
              }
            },
            errorMessage => {
              // parse error, ignore it.
            }
          )
          .catch(err => {
            // Start failed, handle it.
            console.warn(`startScan failed`, err);
          })
          .finally(() => setScannerBusy(false));
      } catch (error) {
        console.log("startScan failed unexpectedly", error);
        setScannerBusy(false);
      }
    }
  };

  const stopScan = async () => {
    console.log(`stopScan.. `);
    setErrorMessage(null);
    if (scannerRef.current) {
      setScannerStarted(false);
      setScannerBusy(true);
      try {
        scannerRef.current
          .stop()
          .then(ignore => {
            console.log(`stopScan completed. ${ignore ? "ignored" : ""}`);
          })
          .catch(err => {
            console.log(`stopScan failed.`, err);
          })
          .finally(() => setScannerBusy(false));
      } catch (error) {
        console.log("stopScan failed unexpectedly", error);
        setScannerBusy(false);
      }
    }
  };

  const initScanner = () => {
    console.log("initScanner");
    const html5QrCode = new Html5Qrcode(qrcodeRegionId);
    scannerRef.current = html5QrCode;
  };

  useEffect(() => {
    initScanner();
    const mobile = window.innerWidth <= 768;
    if (mobile) {
      startScan();
    }
    return () => {
      try {
        if (scannerRef.current) {
          scannerRef.current
            ?.stop()
            ?.then(ignore => console.log("stopped after successful scan"))
            ?.catch(err => console.log("fails to stop after successfull scan result "));
        }
      } catch (error) {
        console.log("exit scanner. fails to stop after successfull scan result ");
      }
    };
  }, []);

  return (
    <>
      <Container maxWidth="xs">
        <Stack orientation="column" spacing={1} mt={0} pt={0}>
          {errorMessage && (
            <Alert severity="error">
              <AlertTitle>{t("scanner2.invalidContent")}</AlertTitle>
              {errorMessage}
            </Alert>
          )}

          <div
            style={{ width: "340px", alignSelf: "center" }}
            id={qrcodeRegionId}
          />

          <Button fullWidth variant="contained" onClick={toggleStart} disabled={scannerBusy}>
            {scannerStarted ? t("scanner2.stop") : t("scanner2.start")}
          </Button>
          <Button
            fullWidth
            sx={{ background: btnColor }}
            variant="contained"
            onClick={toggleCamera}
            startIcon={<CameraswitchIcon />}
            disabled={scannerBusy}
          >
            {t("scanner2.switch")}
          </Button>
        </Stack>
      </Container>
    </>
  );
}
