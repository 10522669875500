import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../context/AuthContext";
import service from "../../services/stands-service";
import suppliersService from "../../services/suppliers-service";
import projectsService from "../../services/projects-service";
import processService from "../../services/process-service";
import authHeader from "../../services/auth-header";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import GridViewIcon from '@mui/icons-material/GridView';
import ListAltIcon from '@mui/icons-material/ListAlt';
import FilterListIcon from "@mui/icons-material/FilterList";
import getQueryParams from "../../services/query-params-helper";
import {
  Alert,
  AlertTitle,
  Badge,
  Button,
  CircularProgress,
  Container,
  Divider,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Tooltip,
  Popover,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import StandItem from "./StandItem";
import StandsList from "./List";
import StandsDashboard from "./Dashboard";
import moment from "moment/min/moment-with-locales";
import { useTranslation } from "react-i18next";

export default function Stands() {
  const { t } = useTranslation();
  const { accountData } = useAuth();
  const [dashboardView, setDashboardView] = useState(false);
  const [stands, setStands] = useState();
  const [suppliers, setSuppliers] = useState();
  const [projects, setProjects] = useState();
  const [steps, setSteps] = useState();
  const [filter, setFilter] = useState({});
  const [filterApplied, setFilterApplied] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [reloadViews, setReloadViews] = useState(0);
  const [childFilter, setChildFilter] = useState();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const getStands = async () => {
    setErrorMessage(null);
    const filterExists = getQueryParams(filter) ? true : false;
    setFilterApplied(filterExists);
    if (!filterExists) {
      setStands(null);
      return;
    }
    setLoading(true);
    await service
      .getStands(filter)
      .then(res => {
        console.log("getStands", res);
        setStands(res);
      })
      .finally(() => {
        setRefresh(false);
        setLoading(false);
      });
  };

  const getSuppliers = async () => {
    suppliersService
      .getSuppliers()
      .then(res => {
        setSuppliers(res);
      })
      .catch(e => console.log(e));
  };

  const getProjects = async () => {
    projectsService
      .getProjects()
      .then(res => {
        setProjects(res);
      })
      .catch(e => console.log(e));
  };

  const getProcessSteps = async () => {
    processService
      .getProcessSteps()
      .then(res => {
        setSteps(res);
      })
      .catch(e => console.log(e));
  };
  const resetFilter = () => {
    setRefresh(true);
    setFilter({
      ...{
        supplier: "",
        supplierId: "",
        project: "",
        projectId: "",
        step: "",
        active: "",
        transportReference: "",
        number: "",
        visualNumber: "",
        order: "",
      },
    });
  };

  const handleFilterClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleApplyFilter = () => {
    console.log('apply filter!');
    setChildFilter(Object.assign({}, filter));
    getStands();
    setAnchorEl(null);
    setReloadViews(reloadViews+1);
  };
  const handleFilterClose = () => {
    if (refresh){
      getStands();
    }
    setReloadViews(reloadViews+1);
    setFilterApplied(getQueryParams(filter) ? true : false);
    setAnchorEl(null);
  };

  const deleteStand = item => {
    console.log("deleteStand", item);
    if (item) {
      let newList = stands.filter(s => s.id !== item.id);
      setStands(newList);
    }
  };

  const viewList = () => {
    console.log('viewList');
    setDashboardView(false);
    localStorage.setItem("stands.dashboardView", false);
  }

  const viewDashboard = () => {
    console.log('viewDashboard');
    setDashboardView(true);
    localStorage.setItem("stands.dashboardView", true);
  }
  const downloadStands = e => {
    console.log(`downloadStages...`);
    const queryParams = getQueryParams(filter);
    let url = `${process.env.REACT_APP_API}/stands/download`;
    if (queryParams) url += queryParams;
    fetch(url, {
      method: "GET",
      headers: authHeader(),
    })
      .then(response => {
        return response.blob();
      })
      .then(response => {
        let blob = window.URL.createObjectURL(new Blob(["\ufeff", response]));
        let anchor = document.createElement("a");
        anchor.setAttribute("download", `stands_${moment().format("YYYY-MM-DD")}.csv`);
        anchor.href = blob;
        anchor.click();
      });
  };

  const downloadStandsExcel = e => {
    console.log(`downloadStages (excel)...`);
    const queryParams = getQueryParams(filter);
    let url = `${process.env.REACT_APP_API}/stands/download/excel`;
    if (queryParams) url += queryParams;
    fetch(url, {
      method: "GET",
      headers: authHeader(),
    })
      .then(response => {
        return response.blob();
      })
      .then(response => {
        let blob = window.URL.createObjectURL(new Blob([response]));
        let anchor = document.createElement("a");
        anchor.setAttribute("download", `stands_${moment().format("YYYY-MM-DD")}.xlsx`);
        anchor.href = blob;
        anchor.click();
      });
  };

  useEffect(() => {
    (async () => {
      try {
        setDashboardView(localStorage.getItem("stands.dashboardView") ? true : false);
        resetFilter();
        getSuppliers();
        getProjects();
        getProcessSteps();
        await getStands();
      } catch (error) {
        console.log(error);
        setErrorMessage(t("stands.getStandFail"));
      } finally {
        setLoading(false);
      }
    })();
  }, []);
  return (
    <Box
      pt={2}
      sx={{
        background: accountData.settings.bodyBackground,
        height: "calc(98vh - 65px)",
        overflow: "auto",
      }}
    >
      <Container maxWidth="md">
        <Grid container>
          <Grid item xs={6} md={8}>
            <Typography mb={2} variant="h6">
              {t("stands.stand")}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4} mb={1}>
            <Box display="flex" justifyContent="flex-end" mr={1}>
              <Stack direction="row" spacing={1}>
                {!dashboardView && (
                  <Tooltip title={t("stands.dashboardView")}>
                    <Fab size="small" color="primary" onClick={viewDashboard}>
                      <GridViewIcon />
                    </Fab>
                  </Tooltip>
                )}
                {dashboardView && (
                  <Tooltip title={t("stands.listView")}>
                    <Fab size="small" color="primary" onClick={viewList}>
                      <ListAltIcon />
                    </Fab>
                  </Tooltip>
                )}
                <Tooltip title={t("stands.filterStand")}>
                  <Fab size="small" color="gray" onClick={handleFilterClick}>
                    {filterApplied && (
                      <Badge color="secondary" variant="dot">
                        <FilterListIcon />
                      </Badge>
                    )}
                    {!filterApplied && <FilterListIcon />}
                  </Fab>
                </Tooltip>
                <Popover
                  id="123"
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleFilterClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Stack spacing={1} m={2}>
                    <Typography p={0} m={0} variant="h6">
                      {t("stands.filterStand")}
                    </Typography>
                    <Divider p={0} />
                    <FormControl fullWidth size="small">
                      <InputLabel>{t("stands.supplier")}</InputLabel>
                      <Select
                        size="small"
                        id="supplier-select"
                        value={filter.supplierId}
                        label={t("stands.supplier")}
                        onChange={e => setFilter({ ...filter, supplierId: e.target.value })}
                      >
                        <MenuItem value="">&nbsp;</MenuItem>
                        {suppliers &&
                          suppliers.map(item => {
                            return (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            );
                          })}{" "}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth size="small">
                      <InputLabel>{t("stands.project")}</InputLabel>
                      <Select
                        id="project-select"
                        value={filter.projectId}
                        label={t("stands.project")}
                        onChange={e => setFilter({ ...filter, projectId: e.target.value })}
                      >
                        <MenuItem value="">&nbsp;</MenuItem>
                        {projects &&
                          projects.map(item => {
                            return (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            );
                          })}{" "}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth size="small">
                      <InputLabel>{t("stands.step")}</InputLabel>
                      <Select
                        size="small"
                        id="process-select"
                        value={filter.step}
                        label={t("stands.step")}
                        onChange={e => setFilter({ ...filter, step: e.target.value })}
                      >
                        <MenuItem value="">&nbsp;</MenuItem>
                        {steps &&
                          steps.map(item => {
                            return (
                              <MenuItem key={item.step} value={item.step}>
                                {item.description}
                              </MenuItem>
                            );
                          })}{" "}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth size="small">
                      <InputLabel>{t("stands.active")}</InputLabel>
                      <Select
                        size="small"
                        id="active-select"
                        value={filter.active}
                        label={t("stands.active")}
                        onChange={e => setFilter({ ...filter, active: e.target.value })}
                      >
                        <MenuItem value="">&nbsp;</MenuItem>
                        <MenuItem value="true">{t("stands.active")}</MenuItem>
                        <MenuItem value="false">{t("stands.inactive")}</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      fullWidth
                      size="small"
                      id="outlined-basic"
                      label={t("stands.stand")}
                      value={filter.visualNumber}
                      onChange={e => setFilter({ ...filter, visualNumber: e.target.value })}
                    />
                    <TextField
                      fullWidth
                      size="small"
                      id="outlined-basic"
                      label={t("stands.order")}
                      value={filter.order}
                      onChange={e => setFilter({ ...filter, order: e.target.value })}
                    />
                    <TextField
                      fullWidth
                      size="small"
                      id="outlined-basic"
                      label={t("stands.transport")}
                      value={filter.transportReference}
                      onChange={e => setFilter({ ...filter, transportReference: e.target.value })}
                    />
                    <Button size="small" variant="contained" onClick={handleApplyFilter}>
                      {t("stands.filter")}
                    </Button>
                    <Button size="small" variant="contained" sx={{ background: "gray" }} onClick={resetFilter}>
                      {t("stands.clear")}
                    </Button>
                    <Button size="small" variant="contained" sx={{ background: "gray" }} onClick={handleFilterClose}>
                      {t("stands.close")}
                    </Button>
                  </Stack>
                </Popover>
                <Box display="flex" >
                  <Tooltip title={t("stands.download")}>
                    <Fab size="small" color="primary" onClick={downloadStandsExcel}>
                      <CloudDownloadIcon />
                    </Fab>
                  </Tooltip>
                </Box>
              </Stack>
            </Box>
          </Grid>
         
        </Grid>
        <Grid item xs={12}>
          {!dashboardView && (
            <StandsList filter={filter} reload={reloadViews}></StandsList>
          )}
          {dashboardView && (
            <StandsDashboard filter={filter} reload={reloadViews}></StandsDashboard>
          )}
        </Grid>
      </Container>
    </Box>
  );
}
