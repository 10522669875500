import axios from "axios";
import getQueryParams from "./query-params-helper";

const getStands = async (filter = null) => {
  const queryParams = getQueryParams(filter);
  let standsUrl = `/stands`;
  if(queryParams) standsUrl+=queryParams;
  const response = await axios.get(standsUrl);
  return response.data;
};

const getDashboard = async (filter = null) => {
  // return [
  //   {id:1, step:'ett', description:'Ett', count: 12}, 
  //   {id:2, step:'tva', description:'Två', count: 9}, 
  //   {id:3, step:'tre', description:'Tre', count: 0}, 
  //   {id:4, step:'fyra', description:'Fyra', count: 88},
  //   {id:5, step:'fyra', description:'Fem', count: 88},
  //   {id:6, step:'fyra', description:'Sex', count: 88},
  //   {id:7, step:'fyra', description:'Sju', count: 88}
  // ];
  const queryParams = getQueryParams(filter);
  let standsUrl = `/stands/dashboard`;
  if(queryParams) standsUrl+=queryParams;
  const response = await axios.get(standsUrl);
  return response.data;
};

const getHistory = async (number, supplierId, fromDate) => {
  let standsUrl = `/stands/history?number=${number}&supplierId=${supplierId}&fromDate=${fromDate}`;
  const response = await axios.get(standsUrl);
  return response.data;
};

const getStandsByVisualNr = async queryParam => {
  const response = await axios.get(`/stands?visualNumber=${queryParam}`);
  return response.data;
};

const getStandsByNr = async queryParam => {
  const response = await axios.get(`/stands?number=${queryParam}`);
  return response.data;
};

const getStandById = async id => {
  const response = await axios.get(`/stands/${id}`);
  return response.data;
};

const addStand = async payload => {
  const response = await axios.post(`/stands`, payload);
  return response.data;
};

const deleteStand = async id => {
  await axios.delete(`/stands/${id}`);
};

export default {
  getStands,
  getStandsByVisualNr,
  getStandsByNr,
  getStandById,
  addStand,
  getHistory,
  deleteStand,
  getDashboard
};
