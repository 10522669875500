import axios from "axios";

const getProjects = async () => {
  const response = await axios.get(`/projects`);
  return response.data;
};

const save = async data => {
  const response = await axios.post(`/projects`, data);
  return response.data;
};

const deleteProject = async id => {
  await axios.delete(`/projects/${id}`);
};

export default {
  getProjects,
  save,
  deleteProject
};
