import React, { useState, useEffect } from "react";
import { btnColor } from "../../Accounts-styles";
import { Navigate, useNavigate, useParams } from "react-router";
import LoginIcon from "@mui/icons-material/Login";
import {
  Box,
  Chip,
  Container,
  Button,
  TextField,
  Stack,
  Typography,
  Alert,
  AlertTitle,
  CircularProgress,
} from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import usersService from "../../services/users-service";
import userActionService from "../../services/userAction-service";
import accountsService from "../../services/accounts-service";
import userActionHelper from "../../services/user-action-helper";
import Header from "../../components/Header";
import AppRegistration from "@mui/icons-material/AppRegistration";
import createManifest from "../../services/pwa-manifest-helper";
import { useTranslation } from "react-i18next";

export default function RegisterUser() {
  const { t } = useTranslation();
  const { account } = useParams();
  const { accountData, setAccountData } = useAuth();
  const navigate = useNavigate();
  const [showError, setShowError] = useState({ status: "", display: false });
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [btnSpinner, setBtnSpinner] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [canSave, setCanSave] = useState(false);
  const [payload, setPayload] = useState({
    name: "",
    email: "",
    password: "",
    accountId: account,
    active: false,
  });

  const setFormValid = data => {
    if (accountData?.domain) {
      if (!data.email.toLowerCase().endsWith(accountData?.domain.toLowerCase())) {
        setCanSave(false);
        return;
      }
    }
    if (data.name.length < 1) {
      setCanSave(false);
      return;
    }
    if (data.password.length < 1) {
      setCanSave(false);
      return;
    }
    setCanSave(true);
  };

  const handleChange = e => {
    setErrorMessage(null);
    setShowError(false);
    setErrorMessage(null);
    const name = e.target.name;
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const data = { ...payload, [name]: value };
    setPayload(data);
    setFormValid(data);
  };

  const getAccount = async () => {
    return await accountsService.getAccount(account);
  };

  const postUserAction = async () => {
    const userAction = userActionHelper.getActivateUserAction(account, payload.email);
    await userActionService.createUserAction(userAction);
  };

  const register = async () => {
    setShowSuccess(false);
    setShowError(false);
    setErrorMessage(null);
    setBtnSpinner(true);
    await usersService
      .createUser(payload)
      .then(() => {
        postUserAction();
        setShowSuccess(true);
        setBtnSpinner(false);
      })
      .catch(error => {
        if (error.response) {
          console.log(error.response);
          setShowError({ display: true, status: "status: " + error.response.status });
          setBtnSpinner(false);
        }
      });
  };

  useEffect(() => {
    (async () => {
      try {
        if (accountData === null || accountData === undefined) {
          console.log("accountData not found.. getting");
          const a = await getAccount();
          console.log("account found", a);
          setAccountData(a);
          createManifest(a);
        }
        setFormValid(payload);
      } catch (error) {
        console.log(error);
        setErrorMessage(t("registerUser.errMess"));
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <Header />

      {accountData && (
        <Box pt={2} sx={{ background: accountData.settings.bodyBackground, height: "90vh" }}>
          <Container
            maxWidth="xs"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {loading && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress sx={{ color: btnColor }} />
              </Box>
            )}
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            <Container sx={{ alignSelf: "center", marginTop: "40px" }}>
              <Typography mb={2} variant="h6">
                {t("registerUser.regUser")}
              </Typography>
              {!showSuccess && (
                <>
                  <Stack spacing={2}>
                    <TextField
                      autoComplete="off"
                      onChange={handleChange}
                      fullWidth={true}
                      id="outlined-basic"
                      name="name"
                      label={t("registerUser.labelName")}
                      variant="filled"
                    />

                    <TextField
                      autoComplete="off"
                      onChange={handleChange}
                      fullWidth={true}
                      id="outlined-basic"
                      name="email"
                      label={t("registerUser.labelEmail")}
                      variant="filled"
                    />
                    {accountData && accountData.domain && (
                      <Alert severity="info">
                        {t("registerUser.alert_1")} @{accountData.domain}
                      </Alert>
                    )}
                    <TextField
                      autoComplete="new password"
                      onChange={handleChange}
                      fullWidth={true}
                      id="outlined-basic"
                      name="password"
                      label={t("registerUser.labelPassword")}
                      variant="filled"
                      type="password"
                    />
                  </Stack>
                  <Stack spacing={2} sx={{ textAlign: "center" }}>
                    <Button
                      fullWidth={true}
                      variant="contained"
                      startIcon={<AppRegistration />}
                      sx={{ marginTop: "20px", background: btnColor }}
                      onClick={register}
                      disabled={!canSave}
                    >
                      {t("registerUser.register")}&nbsp;
                      {btnSpinner && <CircularProgress size={17} sx={{ color: "white" }} />}
                    </Button>
                  </Stack>
                </>
              )}

              <Box mt={2}>
                {showSuccess && (
                  <Alert severity="success">
                    <AlertTitle>{t("registerUser.alertTitle_2")}</AlertTitle>
                    {t("registerUser.alert_2")}
                  </Alert>
                )}
                {showError.display && (
                  <Alert severity="error">
                    <AlertTitle>{t("registerUser.errMess")}</AlertTitle>
                    Error {showError.status}
                  </Alert>
                )}
              </Box>
              <Stack spacing={2} sx={{ textAlign: "center" }} mt={1}>
                <Button
                  fullWidth={true}
                  variant="outlined"
                  sx={{ borderColor: btnColor, color: btnColor }}
                  onClick={() => navigate(`/${account}/Login`)}
                >
                  {t("registerUser.toLogin")}
                </Button>
              </Stack>
            </Container>
          </Container>
        </Box>
      )}
    </>
  );
}
