import React, {useEffect} from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Stack } from "@mui/material";

import { useTranslation } from "react-i18next";

export default function DeleteButton({ itemId, text, data, color, size, style, onClick, variant }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [buttonVariant, setButtonVariant] = React.useState(variant ? variant : "contained");
  const { t } = useTranslation();
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleContinue = event => {
    setAnchorEl(null);
    if (onClick) onClick(itemId);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button aria-describedby={id} variant={buttonVariant} onClick={handleClick} color={color} size="small">
        {t("deleteButton.delete")}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Card sx={{ maxWidth: 200 }}>
          <CardContent>
            <Stack spacing={1} direction="column">
              <Typography gutterBottom variant="h7" component="div">
                {text}
              </Typography>
              <Typography variant="body2">{t("deleteButton.confirm")}</Typography>
              <Stack spacing={1} direction="row">
                <Button variant="contained" size="small" onClick={handleContinue} color="error">
                  {t("deleteButton.continue")}
                </Button>
                <Button variant="contained" size="small" sx={{ background: "gray" }} onClick={handleClose}>
                  {t("deleteButton.undo")}
                </Button>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Popover>
    </div>
  );
}
